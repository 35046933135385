import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack } from '@mui/material';

const AddStoreForm = ({ open, onClose, onSave }) => {
  const [storeName, setStoreName] = useState('');

  const handleSave = () => {
    if (!storeName.trim()) {
      alert('Por favor ingresa un nombre para la tienda.');
      return;
    }
    
    onSave(storeName); // Llama la función onSave con el nombre de la tienda
    setStoreName(''); // Resetea el campo del formulario
    onClose(); // Cierra el formulario
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Agregar Tienda</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ padding: '5px' }}>
          <TextField
            label="Nombre de la Tienda"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStoreForm;
