import React, { useEffect, useState } from 'react';
import './styles.css';
import SplashScreen from './components/SplashScreen';
import SlashSkeletonScreen from './components/SlashSkeletonScreen';


const SplashScreenManager = () => {


  return (
    <SlashSkeletonScreen />
  );
};


export default SplashScreenManager;
