import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ServicioForm from './components/ServicioForm';
import ServicioAddButton from './components/ServicioAddButton';
import ServicioPreview from './components/ServicioPreview';
import decompressServicio from './utils/decompressServicio';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { doc, getDoc } from 'firebase/firestore'; // Importar desde Firebase Firestore
import { db, auth } from '../../firebaseConfig'; // Ajusta la ruta según tu proyecto
import LZString from 'lz-string';


const ServicioManager = ({ storeName  }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviciosArray, setServiciosArray] = useState([]);
  const [newServicio, setNewServicio] = useState({
    titulo: '',
    descripcion: '',
    icono: '',
    color: '#ffffff',
  });
  const [servicioToEdit, setServicioToEdit] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  useEffect(() => { 
    
    const fetchServicios = async () => {
      try {
        const docRef = doc(db, storeName, 'config');
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          const compressedData = docSnap.data()?.servicios || '';
          const decompressedData = decompressServicio(compressedData);
    
          const servicios = Array.isArray(decompressedData) ? decompressedData : [decompressedData];
    
          // Agregar un ID único si falta
          const serviciosConIds = servicios.map((servicio, index) => ({
            ...servicio,
            id: servicio.id || index + 1, // Genera un ID si no existe
          }));
    
          setServiciosArray(serviciosConIds);
        } else {
          setServiciosArray([]);
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setServiciosArray([]);
      }
    };
    fetchServicios();
  }, [storeName]);


  const handleSaveServicio = async (compressedServicioData) => {
    try {
      const decompressedData = decompressServicio(compressedServicioData);
  
      let updatedServicios;
      if (servicioToEdit) {
        // Actualizar servicio existente
        updatedServicios = serviciosArray.map((servicio) =>
          servicio.id === servicioToEdit.id ? { ...servicio, ...decompressedData } : servicio
        );
      } else {
        // Generar un ID único para el nuevo servicio
        const nextId = serviciosArray.length > 0
          ? Math.max(...serviciosArray.map((servicio) => servicio.id || 0)) + 1
          : 1;
  
        const nuevoServicio = { id: nextId, ...decompressedData };
        updatedServicios = [...serviciosArray, nuevoServicio];
      }
  
      setServiciosArray(updatedServicios);
  
      // Comprimir y guardar en Firestore
      const updatedCompressedData = LZString.compressToBase64(JSON.stringify(updatedServicios));
      await saveToFirestore(storeName, 'config', { servicios: updatedCompressedData });
  
      // Reiniciar estado
      setNewServicio({ titulo: '', descripcion: '', icono: '', color: '#ffffff' });
      setServicioToEdit(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    }
  };
  
  const handleEditServicio = (servicio) => {
    setNewServicio({ ...servicio }); // Crear una copia del servicio
    setServicioToEdit(servicio); // Referencia del servicio en edición
    setIsModalOpen(true); // Abrir modal de edición
  };
  
  

  const handleDeleteServicio = async (servicioId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este servicio?')) {
      try {
        // Filtrar el servicio eliminado
        const updatedServicios = serviciosArray.filter((servicio) => servicio.id !== servicioId);
  
        setServiciosArray(updatedServicios);
  
        const updatedCompressedData = LZString.compressToBase64(JSON.stringify(updatedServicios));
        await saveToFirestore(storeName, 'config', { servicios: updatedCompressedData });
  
        console.log('Servicio eliminado:', servicioId);
      } catch (error) {
        console.error('Error al eliminar el servicio:', error);
      }
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewServicio((prev) => ({ ...prev, [name]: value }));
  };
  

  return (
    <Box>
      
      <ServicioPreview 
       serviciosArray={serviciosArray}
       onEdit={handleEditServicio}
       onDelete={handleDeleteServicio}
       user={user}
       />

{user && (
      <ServicioAddButton onClick={() => setIsModalOpen(true)} />
      
    )}

      <ServicioForm
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveServicio}
        newServicio={newServicio}
        handleInputChange={handleInputChange}
        storeName={storeName}
      />
      
    </Box>
  );
};

export default ServicioManager;
