// src/hooks/useStoreName.js
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const useStoreName = () => {
  const urlReplace = process.env.REACT_APP_URL_REPLACE || '';
  const [storeName, setStoreName] = useState('');
  const [isAdminRoute, setIsAdminRoute] = useState(false);
  const { tienda } = useParams();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    // Determinar si es una ruta de administración
    const isAdmin = path.includes('/administrar') //sie sta en la ruta de admin validar si es su tienda y asi
    setIsAdminRoute(isAdmin);

    if (isAdmin) {
      // Si es ruta de administración, usar 'tienda' de los parámetros
      setStoreName(tienda.toLowerCase());
    } else {

      const hostname = window.location.hostname.toLowerCase();
      if (isSubdomain(hostname) && hostname.includes(urlReplace) ) {
        console.log(`${hostname} es un subdominio del proyecto.`);
        const subhostname = getHostPart(hostname,0);
        setStoreName(subhostname);
      } else {
        console.log(`${hostname} es un dominio raíz o un sub de otro dominio.`);
        if (isSubdomain(hostname)){
           const hostname = getHostPart(hostname,1);
           setStoreName(hostname);
        }else{
          console.log(getHostPart(hostname,0));
          setStoreName(getHostPart(hostname,0));
        }
      }

    }
  }, [location, tienda, urlReplace]);
  
  function isSubdomain(hostname) {
    // Divide el hostname por los puntos
    const parts = hostname.split('.');
    // Si tiene más de 2 partes, probablemente sea un subdominio
    // Ejemplo: "sub.mivisualization.com" -> ['sub', 'mivisualization', 'com']
    //          "mivisualization.com" -> ['mivisualization', 'com']
    if (parts.length > 2) {
      return true; // Es un subdominio
    }
    // Para dominios como "co.uk", "gov.uk", puedes añadir excepciones
    const knownSecondLevelDomains = ['co.uk', 'gov.uk', 'edu.au'];
    const domain = parts.slice(-2).join('.'); // Últimos 2 niveles
    if (knownSecondLevelDomains.includes(domain)) {
      return parts.length > 3; // Si hay más niveles, es un subdominio
    }
    return false; // Es un dominio raíz
  }

  function getHostPart(hostname,int) {
    const parts = hostname.split('.');
    if (parts.length > 1) {
      return parts[int]; // Retorna la palabra más a la izquierda
    }
    return null; // No es un subdominio
  }

  return { storeName, isAdminRoute };
};

export default useStoreName;
