// src/hooks/useStore.js
import { useState ,useEffect} from 'react';
import { 
  uploadFile, 
  saveToFirestore, 
  getAuthenticatedUser, 
  getDocuments,
  checkDocumentExists,
  getDocumentById  
} from '../../../utils/firebaseUtils'; // Importar funciones reutilizables
import { storage } from '../../../firebaseConfig';
import { deleteDoc, updateDoc, arrayRemove , arrayUnion , getFirestore, collection, getDocs, query, where,doc ,getDoc  } from "firebase/firestore";
import { ref, deleteObject, listAll } from "firebase/storage";
import { getAuth,  setPersistence, browserLocalPersistence, onAuthStateChanged  } from "firebase/auth"; 
import LZString from 'lz-string';


const db = getFirestore();
const auth = getAuth();

// Configuración de persistencia de autenticación
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Configura el dominio de las cookies en el dominio raíz
    auth.settings.appVerificationDisabledForTesting = true;  // Solo para desarrollo local
    auth.settings.domain = '.mispacecode.com';  // Dominio raíz para todos los subdominios
    console.log("Persistencia de sesión configurada correctamente.");
  })
  .catch((error) => {
    console.error("Error al configurar la persistencia de sesión:", error);
  });


const useStore = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [stores, setStores] = useState([]);
  const [storeConfig, setStoreConfig] = useState(null);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  useEffect(() => {
    // Detectar cambios en el estado de autenticación
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user.email);
      } else {
        setAuthenticatedUser(null);
      }
    });
    
    // Limpiar el observador al desmontar el componente
    return () => unsubscribe();
  }, []);



  // Función para manejar errores y carga de forma eficiente
  const executeWithLoading = async (fn) => {
    setLoading(true);
    setError(null);
    try {
      return await fn();
    } catch (err) {
      //console.error(err);
      setError(err.message);
      return { success: false, error: err.message };
    } finally {
      setLoading(false);
    }
  };

  // Función para registrar la tienda
  const registerStore = async (storeData) =>
  executeWithLoading(async () => {
    const storeName = storeData.title.replace(/\s+/g, "_");

    // Verificar si la tienda ya existe usando la función genérica
    const storeExists = await checkDocumentExists(storeName, "config");
    if (storeExists) {
      throw new Error("La tienda ya está registrada");
    }

    const email = getAuthenticatedUser();
    const currentYear = new Date().getFullYear();
    // Crear el footer basado en los datos ingresados
    const footer = "© "+currentYear+" "+storeData.title+". Todos los derechos reservados.";

    // Guardar la tienda en la colección del usuario autenticado
    await saveToFirestore("registered-users", email, {
      stores: arrayUnion(storeName),
    });

    // Crear registro de tienda con logo, footer y creador
    await saveToFirestore(storeName, "config", {
      "created-by": email,
      footer,
    });

    return { success: true };
  });

  const deleteStore = async (storeName) =>
  executeWithLoading(async () => {
    try {
      console.log("deleteStore", storeName);

      const userEmail = authenticatedUser;

      if (!userEmail) {
        throw new Error("No hay usuario autenticado.");
      }

      // Referencia al documento del usuario
      const userDocRef = doc(db, "registered-users", userEmail);

      // Eliminar la tienda del campo "stores" en el documento del usuario
      await updateDoc(userDocRef, {
        stores: arrayRemove(storeName),
      });

      // Función recursiva para eliminar subcolecciones y documentos
      const deleteCollectionRecursively = async (collectionPath, subCollections = []) => {
        const collectionRef = collection(db, collectionPath);
        const docsSnapshot = await getDocs(collectionRef);

        // Iterar sobre los documentos de la colección
        for (const docSnapshot of docsSnapshot.docs) {
          const docRef = doc(db, collectionPath, docSnapshot.id);

          // Eliminar manualmente subcolecciones conocidas
          for (const subCollectionName of subCollections) {
            await deleteCollectionRecursively(`${collectionPath}/${docSnapshot.id}/${subCollectionName}`);
          }

          // Eliminar el documento actual
          await deleteDoc(docRef);
        }
      };

      // Llamar a la eliminación recursiva para la colección de la tienda
      const knownSubCollections = ["subCollection1", "subCollection2"]; // Especifica tus subcolecciones aquí
      await deleteCollectionRecursively(storeName, knownSubCollections);

      // Eliminar todas las subcarpetas y archivos de Firebase Storage recursivamente
      const deleteFolderRecursively = async (folderPath) => {
        const folderRef = ref(storage, folderPath);
        const folderContents = await listAll(folderRef);

        // Eliminar archivos en la carpeta
        for (const item of folderContents.items) {
          await deleteObject(item);
          console.log(`Archivo eliminado: ${item.name}`);
        }

        // Llamar recursivamente para eliminar subcarpetas
        for (const subFolder of folderContents.prefixes) {
          await deleteFolderRecursively(subFolder.fullPath);
        }
      };

      // Llamar a la eliminación recursiva para la carpeta de la tienda
      await deleteFolderRecursively(`${storeName}/`);

      console.log(`Tienda "${storeName}" eliminada completamente.`);
      return { success: true };
    } catch (err) {
      console.error("Error al eliminar la tienda:", err);
      throw err;
    }
  });
    
  const getStores = async () => {
      
      const userEmail = authenticatedUser;
      
      if (!userEmail) {
        console.error("No hay usuario autenticado");
        return [];
      }
    
      try {
        // Referencia al documento del usuario dentro de "registered-users"
        const userDocRef = doc(db, "registered-users", userEmail);
        
        // Obtener el documento del usuario
        const userDoc = await getDoc(userDocRef);
    
        if (!userDoc.exists()) {
          console.error("No se encontró el documento del usuario");
          return [];
        }
    
        // Extraer el campo "stores" del documento
        const userData = userDoc.data();
        const userStores = userData.stores || [];
    
        //console.log("Tiendas del usuario:", userStores);
        return userStores;
      } catch (error) {
        console.error("Error al obtener las tiendas:", error);
        return [];
      }
  };


  // Función para obtener la configuración de una tienda por su nombre (dominio)
  const getStoreConfig = async (storeName) =>
    executeWithLoading(async () => {
      try {
        const config = await getDocumentById(storeName, 'config'); // Llamamos a la función genérica
        if (config) {
          setStoreConfig(config);
          return { success: true, data: config };
        } else {
          throw new Error('No se encontró la configuración de la tienda');
        }
      } catch (err) {
        console.error('Error al obtener la configuración de la tienda:', err);
        throw err;
      }
    });


  return { registerStore, getStores, stores, loading, error , getStoreConfig, storeConfig ,authenticatedUser , deleteStore};
};

export default useStore;
