import React from 'react';
import GalleryPreview from './GalleryPreview'; // Asegúrate de importar tus componentes
import InfoProducto from './InfoProducto';

const DetalleProductoPreview = ({ 
  
  availability,
  careInstructions,
  colors,
  description, 
  id,
  images,
  material,
  name, 
  oldPrice,
  paymentLink,
  price,
  productType,
  schema,
  seo,
  sizes ,
  vendor, 
  
  stock,
  descriptionLarga,  
  paymentType,//arraycon tipos d epagos disponibles
  infoEnvio,
  infoAdicional


}) => {

  const imgUrl = process.env.REACT_APP_URL_SSL +'://'+ process.env.REACT_APP_URL_REPLACE;

  return (
    <div>
    <div class="page-title-area text-left">
            <div class="container">
                <ul>
                    <li><a href="#">Inicio</a></li>
                    <li>{name}</li>
                </ul>
            </div>
    </div>
    <section class="products-details-area ptb-60 text-left bg-white p-4 rounded shadow pt-5 ">
    <div class="container">
    <div class="row">
      <div className="col-lg-6 col-md-6">
          <GalleryPreview images={images} />
      </div>
      <div className="col-lg-6 col-md-6">
                          <div className="product-details-content">
                              <h3>{name}</h3>

                              <div className="price">
                                  <span className="new-price">S/.{price}</span>
                              </div>

                              <div className="product-review">
                                  <div className="rating">
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star"></i>
                                      <i className="fas fa-star-half-alt"></i>
                                  </div>
                                  <a href="#" className="rating-count">3 reviews</a>
                              </div>

                              <p>{description}</p>

                              <ul className="product-info">
                                  <li><span>Vendedor:</span> <a href="#">{vendor}</a></li>
                                  <li><span>Availability:</span> <a href="#">In stock (7 items)</a></li>
                                  <li><span>Tipo de Producto:</span> <a href="#">{productType}</a></li>
                                  <li>
                                    <div className="mt-1">
                                      <h4 className="text-base text-gray-600 mb-1.5">Color:</h4>
                                      <ul className="flex space-x-2 pt-1">
                                        {colors.map((color, index) => (
                                          <li key={index}>
                                            <a
                                              href="#"
                                              title={color}
                                              className={`w-5 h-5 rounded-full border border-gray-300 hover:outline hover:outline-black hover:outline-1`}
                                              style={{ backgroundColor: color }} // Establece el color dinámicamente
                                            >
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </li>

                              </ul>

                              <div className="product-size-wrapper">
                                <h4>Tamaño:</h4>
                                <ul>
                                  {sizes.map((size, index) => (
                                    <li key={index} className={index === 1 ? 'active' : ''}>
                                      <a href="#">{size}</a>
                                    </li>
                                  ))}
                                </ul>
                              </div>

                             

                              <div className="product-info-btn">
                                  <a href="#" data-bs-toggle="modal" data-bs-target="#sizeGuideModal"><i className="fas fa-crop"></i>Guía de tallas</a>
                                  <a href="#" data-bs-toggle="modal" data-bs-target="#productShippingModal"><i className="fas fa-truck"></i> Envío</a>
                                  <a href="contact-us.html"><i className="far fa-envelope"></i>Pregunte por este producto</a>
                              </div>

                              

                            

                              <div className="buy-checkbox-btn">
                                  <div className="item">
                                      <a href="#" className="btn btn-primary">Compra ahora!</a>
                                  </div>
                              </div>

                              <div className="custom-payment-options ">
                                  <span>Pago seguro garantizado:</span>
                                  <div className="payment-methods flex space-x-2">
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/1.svg`} alt="image" /></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/2.svg`} alt="image"/></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/3.svg`} alt="image"/></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/4.svg`} alt="image"/></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/5.svg`} alt="image"/></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/6.svg`} alt="image"/></a>
                                      <a href="#"><img src={`${imgUrl}/assets/img/payment-image/7.svg`} alt="image"/></a>
                                  </div>
                              </div>
                          </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <InfoProducto descripcion={description} color={colors} size={sizes} />
      </div>
    </div>
    </div>
    </section>

    </div>
  );
};

export default DetalleProductoPreview;
