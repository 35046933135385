import React from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const LogoEditButton = ({ onClick }) => (
  <IconButton
    size="small"
    onClick={onClick}
    sx={{
      backgroundColor: 'white',
      boxShadow: 1,
      '&:hover': { backgroundColor: 'lightgrey' },
    }}
  >
    <EditIcon fontSize="small" />
  </IconButton>
);

export default LogoEditButton;
