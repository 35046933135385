import React, { useState,useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack, TextField } from '@mui/material';
import compressLogo from '../utils/compressLogo';
import LogoPreview from './LogoPreview';
import { uploadFile } from '../../../utils/firebaseUtils';

const LogoForm = ({ storeName, open, onClose, onSave, currentLogo }) => {

  const [desktopLogo, setDesktopLogo] = useState('');
  const [mobileLogo, setMobileLogo] = useState('');
  const [desktopWidth, setDesktopWidth] = useState(200);
  const [desktopHeight, setDesktopHeight] = useState(40);
  const [mobileWidth, setMobileWidth] = useState(200);
  const [mobileHeight, setMobileHeight] = useState(40);
  
  const [desktopFile, setDesktopFile] = useState(null); // Archivo de escritorio
  const [mobileFile, setMobileFile] = useState(null);   // Archivo móvil
  const [loading, setLoading] = useState(false); // Estado de carga
  
  // Sincronizar estados con `currentLogo`
  useEffect(() => {
    if (currentLogo) {
      setDesktopLogo(currentLogo.desktop || '');
      setMobileLogo(currentLogo.mobile || '');
      setDesktopWidth(currentLogo.desktopWidth || 200);
      setDesktopHeight(currentLogo.desktopHeight || 40);
      setMobileWidth(currentLogo.mobileWidth || 200);
      setMobileHeight(currentLogo.mobileHeight || 40);
    }
  }, [currentLogo]);

  const handleSave = async () => {
    setLoading(true);
    try {
      // Subir imágenes seleccionadas si existen
      const desktopLogoUrl = desktopFile
        ? await uploadFile(desktopFile, storeName+'/logo/desktop')
        : desktopLogo;

      const mobileLogoUrl = mobileFile
        ? await uploadFile(mobileFile, storeName+'/logo/mobile')
        : mobileLogo;

      // Generar los datos comprimidos
      const compressedData = compressLogo({
        desktop: desktopLogoUrl,
        mobile: mobileLogoUrl,
        desktopWidth,
        desktopHeight,
        mobileWidth,
        mobileHeight,
      });

      // Enviar los datos procesados
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error('Error al guardar:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event, setFile, setPreview) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file); // Guardar el archivo seleccionado
      const reader = new FileReader();
      reader.onload = (e) => setPreview(e.target.result); // Generar vista previa
      reader.readAsDataURL(file);
    }
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Logo</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <LogoPreview logo={desktopLogo} width={desktopWidth} height={desktopHeight} />
          <Button variant="outlined" component="label">
            Subir Logo para Escritorio
            <input type="file" hidden onChange={(e) => handleFileChange(e, setDesktopFile, setDesktopLogo)} />
          </Button>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Ancho (Escritorio)"
              type="number"
              value={desktopWidth}
              onChange={(e) => setDesktopWidth(Number(e.target.value))}
            />
            <TextField
              label="Alto (Escritorio)"
              type="number"
              value={desktopHeight}
              onChange={(e) => setDesktopHeight(Number(e.target.value))}
            />
          </Stack>

          <LogoPreview logo={mobileLogo} width={mobileWidth} height={mobileHeight} />
          <Button variant="outlined" component="label">
            Subir Logo para Móvil
            <input type="file" hidden  onChange={(e) => handleFileChange(e, setMobileFile, setMobileLogo)} />
          </Button>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Ancho (Móvil)"
              type="number"
              value={mobileWidth}
              onChange={(e) => setMobileWidth(Number(e.target.value))}
            />
            <TextField
              label="Alto (Móvil)"
              type="number"
              value={mobileHeight}
              onChange={(e) => setMobileHeight(Number(e.target.value))}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {loading ? 'Guardando...' : 'Guardar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoForm;
