// src/components/MainBanner2.js
import React, { useState, useEffect } from 'react';
import { saveToFirestore, getAuthenticatedUser, uploadFile } from '../../../utils/firebaseUtils';
import LZString from 'lz-string';
import BannerSkeleton2 from './BannerSkeleton2';
import { auth } from '../../../firebaseConfig';
import { useNavigate , useLocation , useParams} from 'react-router-dom'; 
import BannerEditorModal from './BannerEditorModal';
import BannerEditButton from './BannerEditButton';


const MainBanner2 = ({ compressedBanner,refreshStoreConfig,storeName }) => {

  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  // Valores por defecto para bannerData
  const defaultBannerData = {
    title1: 'Información Accesible y',
    title: 'Controlada para Todos',
    subtitle: '¡Ropa hecha para ti!',
    description:
      'Plataforma intuitiva, escalable, segura y unificada para la visualización, descarga y edición de reportes y documentos.',
    image: 'assets/img/default-banner.jpg', // Imagen por defecto
    image2: 'assets/img/default-banner.jpg',
    buttonText: 'Comenzar ahora',
    buttonText2: 'Comenzar ahora 2',
    buttonColor: '#1976d2',
    buttonColor2: '#1976d2',
    buttonTextColor: '#ffffff',
    buttonTextColor2: '#ffffff',
    buttonAction: 'https://example.com',
    buttonAction2: 'https://example2.com',
    subtitleColor: '#000000',
    titleColor: '#000000',
    titleColor2: '#000000',
    descriptionColor: '#000000',
    bannerType: 'MainBanner2', // Nuevo campo
  };

  const [bannerData, setBannerData] = useState(defaultBannerData);
  const [previewImage, setPreviewImage] = useState(defaultBannerData.image);
  const [previewImage2, setPreviewImage2] = useState(defaultBannerData.image2);


  // Descomprimir compressedBanner cuando cambie
  /*useEffect(() => {
    console.log('Compressed banner received2:', compressedBanner);
    if (compressedBanner) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        console.log('Decompressed banner data2:', decompressedData);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          console.log('Parsed banner data2:', parsedData);
          const bannerData = {
            ...defaultBannerData,
            ...parsedData,
            bannerType: parsedData.bannerType || defaultBannerData.bannerType,
          };
          setBannerData(bannerData);
          //setPreviewImage(bannerData.image || defaultBannerData.image);
          setPreviewImage(parsedData.image || parsedData.image2 || defaultBannerData.image);
          console.log('Final banner data2:', bannerData);
        } else {
          console.error('Error al descomprimir el banner: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el banner:', error);
      }
    } else {
      console.log('No se recibió compressedBanner2.');
      setBannerData(defaultBannerData);
      setPreviewImage(defaultBannerData.image);
    }
  }, [compressedBanner]);*/

  useEffect(() => {
    //console.log('Compressed banner received2:', compressedBanner);
    if (compressedBanner) {
      try {
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        //console.log('Decompressed banner data2:', decompressedData);
        if (decompressedData) {
          const parsedData = JSON.parse(decompressedData);
          //console.log('Parsed banner data2:', parsedData);
          const bannerData = {
            ...defaultBannerData,
            ...parsedData,
            bannerType: parsedData.bannerType || defaultBannerData.bannerType,
          };
          setBannerData(bannerData);
          setPreviewImage(parsedData.image || defaultBannerData.image); // Establece previewImage desde image
        } else {
          console.error('Error al descomprimir el banner: decompressedData es null.');
        }
      } catch (error) {
        console.error('Error al descomprimir el banner:', error);
      }
    } else {
      //console.log('No se recibió compressedBanner2.');
      setBannerData(defaultBannerData);
      setPreviewImage(defaultBannerData.image);
    }
  }, [compressedBanner]);
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(`Change detected - Name2: ${name}, Value: ${value}`);
    setBannerData((prev) => ({ ...prev, [name]: value }));
    //console.log('Updated bannerData2:', bannerData);
  };

  const handleImageChange = (e, imageKey) => {
    const file = e.target.files[0];
    if (file) {
        const imageUrl = URL.createObjectURL(file);
        if (imageKey === 'image') {
          setPreviewImage(imageUrl);
        } else if (imageKey === 'image2') {
          setPreviewImage2(imageUrl);
        }
        setBannerData((prev) => ({ ...prev, [imageKey]: file })); // Guarda el objeto File directamente
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      //console.log('Data to save in banner (including bannerType):', bannerData);
      const userEmail = getAuthenticatedUser();
      const userId = userEmail.replace('@', '_').replace('.', '_');
  
      let imageUrl = bannerData.image;
      let imageUrl2 = bannerData.image2;
  
      // Verifica y sube la imagen principal (image)
      if (bannerData.image instanceof File) {
        imageUrl = await uploadFile(bannerData.image, storeName+'/banners/fondo');
      }
  
      // Verifica y sube la imagen secundaria (image2)
      if (bannerData.image2 instanceof File) {
        imageUrl2 = await uploadFile(bannerData.image2, storeName+'/banners/secundario');
      }
  
      const bannerToSave = {
        ...bannerData,
        image: imageUrl,
        image2: imageUrl2,
      };
  
      // Convierte los datos del banner a una cadena JSON
      const bannerJson = JSON.stringify(bannerToSave);
  
      // Comprime la cadena JSON utilizando compressToBase64
      const banner = LZString.compressToBase64(bannerJson);
  
      //console.log('Banner comprimido:', banner);
  
      // Guarda en Firestore utilizando saveToFirestore
      await saveToFirestore(storeName, 'config', { banner });
  
      // Actualiza el estado local con los datos guardados
      setBannerData(bannerToSave);
      setPreviewImage(imageUrl);
  
      //console.log('Banner guardado con éxito');
      handleClose();
  
      // Refresca la configuración de la tienda si la función está disponible
      if (refreshStoreConfig) {
        refreshStoreConfig();
      }
    } catch (error) {
      console.error('Error al guardar el banner:', error);
    } finally {
      setLoading(false);
    }
  };
  

  return loading ? (
    <BannerSkeleton2 />
  ) : (
    <div
      className="w-full flex  items-center justify-center bg-cover bg-center h-[1080px] relative"
      style={{
        backgroundImage: `url(${previewImage})`,
        height: '800px',
      }}
    >
      <div className="w-full flex flex-col lg:flex-row items-center  justify-evenly p-8 relative">
        {/* Sección de texto */}
        <div className="max-w-xl text-left">
          <h1 className="text-5xl font-bold" style={{ color: bannerData.titleColor1 }}>
            {bannerData.title1}
          </h1>
          <h1 className="text-5xl font-bold" style={{ color: bannerData.titleColor }}>
            {bannerData.title}
          </h1>
          <p className="text-xl mt-4" style={{ color: bannerData.descriptionColor }}>
            {bannerData.description}
          </p>
          <div className="mt-6 flex gap-4">
            <a
              href={bannerData.buttonAction}
              className="px-6 py-2 font-semibold rounded-lg shadow-md transition duration-300"
              style={{
                backgroundColor: bannerData.buttonColor,
                color: bannerData.buttonTextColor,
              }}
            >
              {bannerData.buttonText}
            </a>
            {/* Puedes agregar otro botón o enlace aquí si es necesario */}
            <a
              href={bannerData.buttonAction2}
              className="px-6 py-2 font-semibold rounded-lg shadow-md transition duration-300"
              style={{
                backgroundColor: bannerData.buttonColor2,
                color: bannerData.buttonTextColor2,
              }}
            >
              {bannerData.buttonText2}
            </a>
          </div>
        </div>

        {/* Columna de imagen */}
        <div className="hidden lg:w-[600px] lg:h-[500px] bg-gray-200 rounded-lg lg:flex items-center justify-center shadow-xl">
          <img
            src={bannerData.image2 || defaultBannerData.image2}
            alt="Imagen Secundaria del Banner"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>


        
        {user && (
          <BannerEditButton onClick={handleOpen}/>
        )}

        <BannerEditorModal
          open={open}
          handleClose={handleClose}
          bannerData={bannerData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          //handleImageChange={(e) => handleImageChange(e, 'image')}
          loading={loading}
          bannerType="MainBanner2"
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};

export default MainBanner2;