import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { uploadFile } from '../../../utils/firebaseUtils'; // Ajusta según tu configuración
import compressServicio from '../utils/compressServicio';

const ServicioForm = ({ open, onClose, onSave, newServicio, handleInputChange, storeName }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null); // Para manejar el archivo seleccionado

  
  const generateRandomId = () => Math.floor(Math.random() * 1000000);

  // Manejador del cambio de archivo
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile); // Solo guardamos el archivo seleccionado, no lo subimos aún
    }
  };

  // Guardar el servicio
  const handleSave = async () => {
    if (!newServicio.titulo || !newServicio.descripcion || !file) {
      alert('Por favor completa todos los campos antes de agregar el servicio.');
      return;
    }

    try {
      setLoading(true);

      // Subimos el archivo solo cuando se hace clic en "Agregar"
      const randomId = generateRandomId();
      const filePath = `${storeName}/servicio/${randomId}`;
      const iconUrl = await uploadFile(file, filePath);

      // Creamos el objeto comprimido con los datos finales
      const compressedData = compressServicio({
        titulo: newServicio.titulo,
        descripcion: newServicio.descripcion,
        icono: iconUrl,  // Usamos la URL de la imagen subida
        color: newServicio.color || '#ffffff',
      });

      // Llamamos a onSave para guardar el servicio
      onSave(compressedData);

      // Cerramos el modal
      onClose();
    } catch (error) {
      console.error('Error al guardar el servicio:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-service-modal"
      aria-describedby="modal-to-add-service"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2 id="add-service-modal">Agregar Servicio</h2>
        <TextField
          fullWidth
          margin="normal"
          label="Título"
          name="titulo"
          value={newServicio.titulo}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Descripción"
          name="descripcion"
          value={newServicio.descripcion}
          onChange={handleInputChange}
          multiline
          rows={4}
        />
        <Button
          variant="outlined"
          component="label"
          fullWidth
          style={{ marginTop: '16px' }}
        >
          {loading ? 'Subiendo ícono...' : 'Subir Ícono'}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleFileChange}
            disabled={loading}
          />
        </Button>
        {file && (  // Solo mostramos la imagen si se ha seleccionado
          <div style={{ marginTop: '16px', textAlign: 'center' }}>
            <img
              src={URL.createObjectURL(file)}  // Usamos `URL.createObjectURL` para mostrar la imagen seleccionada sin subirla aún
              alt="Icono seleccionado"
              style={{ width: '50px', height: '50px', objectFit: 'cover' }}
            />
          </div>
        )}
        <div style={{ marginTop: '16px' }}>
          <label htmlFor="color-picker" style={{ display: 'block', marginBottom: '8px' }}>
            Seleccionar Color:
          </label>
          <input
            type="color"
            id="color-picker"
            name="color"
            value={newServicio.color || '#ffffff'}
            onChange={handleInputChange}
            style={{
              width: '100%',
              height: '40px',
              border: 'none',
              cursor: 'pointer',
            }}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          style={{ marginTop: '16px' }}
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Agregar'}
        </Button>
      </Box>
    </Modal>
  );
};

export default ServicioForm;
