import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import FacilityArea from '../components/FacilityArea';
import Footer from '../components/Footer';
import useStore from '../hooks/useStore'; // Importar el hook
import Blog from '../components/Blog/BlogManager';
import { doc, getDoc , getDocFromCache, getDocFromServer } from 'firebase/firestore'; // Firebase imports
import { db } from '../firebaseConfig'; // Firebase config
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LZString from 'lz-string';
import ServicioManager from '../components/Servicios/ServicioManager';
import BannerManager from '../components/Banner_new/BannerManager';
import useStoreName from '../hooks/useStoreName'; 
import BeneficiosManager from '../components/Beneficios/BeneficiosManager';
import NavbarManager from '../components/Navbar/NavbarManager';
import ProductoManager from '../components/Producto/ProductoManager';
import SplashScreenManager from '../components/SplashScreen/SplashScreenManager';
import { Tooltip, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';



import TikTokManager from '../components/TikTok/TiktokManager';



const MarketTema1 = () => {

  const { storeName, isAdminRoute } = useStoreName();
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [storeConfig, setStoreConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { tienda } = useParams();


  /*const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config');
      const storeDoc = await getDoc(storeDocRef);
      if (storeDoc.exists()) {
        const data = storeDoc.data();
        console.log('Datos de configuración de la tienda:', data);
        setStoreConfig(data);
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }
    } catch (err) {
      console.error('Error al obtener la configuración de la tienda:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };*/

  const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config');

      let storeDoc;
      try {
        // Intenta obtener el documento desde la caché
        storeDoc = await getDocFromCache(storeDocRef);
        console.log('Datos obtenidos desde la caché:', storeDoc.data());
      } catch (cacheError) {
        // Si falla, obtén el documento desde el servidor
        storeDoc = await getDocFromServer(storeDocRef);
        console.log('Datos obtenidos desde el servidor:', storeDoc.data());
      }

      if (storeDoc.exists()) {
        const data = storeDoc.data();
        setStoreConfig(data);
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }
    } catch (err) {
      console.error('Error al obtener la configuración de la tienda:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };
    
  
  useEffect(() => {
    if (storeName) {
      fetchStoreConfig();
    } 
  }, [storeName]); 


  const refreshStoreConfig = async () => {
    await fetchStoreConfig();
  };





  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <div className={`splash-screen ${loading ? 'splash-screen--hidden' : 'splash-screen--visible'}`}>
        {error && <Alert severity="error">{error}</Alert>}
        {storeConfig && 
          <>
            <NavbarManager  
                storeName={storeName} 
                compressedLogo={storeConfig.logoData} 
                compressedNavbar={storeConfig.navbarData} 
            /> 
            <BannerManager storeName={storeName} compressedBanner={storeConfig.banner}/>

            <ServicioManager storeName={storeName} />
            <BeneficiosManager storeName={storeName}/>

            <ProductoManager storeName={storeName} isAdminRoute={isAdminRoute}  />

            <Blog storeName={storeName} isAdminRoute={isAdminRoute}/>
            <Footer storeName={storeName} compressedFooter={storeConfig.footer} />
          
          </>
        } 


          <TikTokManager storeName={storeName}/>
      
      


      </div>
      <div className={`splash-screen ${loading ? 'splash-screen--visible' : 'splash-screen--hidden'}`}>
        <SplashScreenManager />
      </div>
    </div>
  );
};

export default MarketTema1;