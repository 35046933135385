import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import FacilityArea from '../components/FacilityArea';
import Footer from '../components/Footer';
import BlogDetail from '../components/Blog/components/BlogDetail';
import { doc, getDoc , getDocFromCache, getDocFromServer } from 'firebase/firestore'; // Firebase imports
import { db } from '../firebaseConfig'; // Firebase config
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import useStoreName from '../hooks/useStoreName'; 
import NavbarManager from '../components/Navbar/NavbarManager';
import SplashScreenManager from '../components/SplashScreen/SplashScreenManager';

const Blog = () => {

  const { storeName, isAdminRoute } = useStoreName();
  const urlReplace = process.env.REACT_APP_URL_REPLACE;
  const [storeConfig, setStoreConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { tienda } = useParams();

  const fetchStoreConfig = async () => {
    try {
      const storeDocRef = doc(db, storeName, 'config');

      let storeDoc;
      try {
        // Intenta obtener el documento desde la caché
        storeDoc = await getDocFromCache(storeDocRef);
        console.log('Datos obtenidos desde la caché:', storeDoc.data());
      } catch (cacheError) {
        // Si falla, obtén el documento desde el servidor
        storeDoc = await getDocFromServer(storeDocRef);
        console.log('Datos obtenidos desde el servidor:', storeDoc.data());
      }

      if (storeDoc.exists()) {
        const data = storeDoc.data();
        setStoreConfig(data);
      } else {
        throw new Error('No se encontró la configuración de la tienda.');
      }
    } catch (err) {
      console.error('Error al obtener la configuración de la tienda:', err);
      setError(`Error al obtener los datos: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };
    
  
  useEffect(() => {
    if (storeName) {
      fetchStoreConfig();
    } 
  }, [storeName]); 


  const refreshStoreConfig = async () => {
    await fetchStoreConfig();
  };

  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <div>
      <div className={`splash-screen ${loading ? 'splash-screen--hidden' : 'splash-screen--visible'}`}>
        {error && <Alert severity="error">{error}</Alert>}
        {storeConfig && 
          <>
            <NavbarManager  storeName={storeName} compressedLogo={storeConfig.logoData} compressedNavbar={storeConfig.navbarData} /> 
            <BlogDetail storeName={storeName} isAdminRoute={isAdminRoute}/>
            <Footer storeName={storeName} compressedFooter={storeConfig.footer} />
          </>
        } 
      </div>
      <div className={`splash-screen ${loading ? 'splash-screen--visible' : 'splash-screen--hidden'}`}>
        <SplashScreenManager />
      </div>
    </div>
  );
};

export default Blog;