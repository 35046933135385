// src/utils/firebaseUtils.js
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  collection,
  setDoc,
  getDocs,
  doc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDoc,
  deleteDoc
} from 'firebase/firestore';
import { storage, db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';


// Función para subir archivos a Firebase Storage
export const uploadFile = async (file, path) => {
  if (!file) return '';

  const fileRef = ref(storage, `${path}/${file.name}_${Date.now()}`);
  await uploadBytes(fileRef, file);
  return await getDownloadURL(fileRef);
};

// Función para guardar datos en Firestore
export const saveToFirestore = async (collectionPath, documentId, data) => {
  // Descomponer la ruta de la colección en segmentos
  const pathSegments = collectionPath.split('/');

  // Construir la referencia al documento usando los segmentos de la ruta
  const docRef = doc(db, ...pathSegments, documentId);

  // Guardar los datos en Firestore
  await setDoc(docRef, data, { merge: true });
};

// Función para obtener el usuario autenticado
export const getAuthenticatedUser = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No hay un usuario autenticado');
  return user.email;
};

// Función para obtener documentos de una colección con opciones de filtrado y paginación
export const getDocuments = async (collectionPath, options = {}) => {
  const {
    filters = [],
    orderByField = null,
    orderDirection = 'asc',
    limitCount = 10,
    startAfterDoc = null,
  } = options;

  
  
  // Descomponer la ruta de la colección en segmentos
  const pathSegments = collectionPath.split('/');

  // Construir la referencia a la colección usando los segmentos de la ruta
  const colRef = collection(db, ...pathSegments);

  // Construir la consulta
  let q = query(colRef);

  // Aplicar filtros
  filters.forEach((filter) => {
    q = query(q, where(filter.field, filter.operator, filter.value));
  });

  // Ordenar resultados
  if (orderByField) {
    q = query(q, orderBy(orderByField, orderDirection));
  }

  // Limitar resultados
  if (limitCount) {
    q = query(q, limit(limitCount));
  }

  // Paginación
  if (startAfterDoc) {
    q = query(q, startAfter(startAfterDoc));
  }

  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Función genérica para verificar si un documento existe
export const checkDocumentExists = async (collectionPath, documentId) => {
  // Descomponer la ruta de la colección en segmentos
  const pathSegments = collectionPath.split('/');

  // Construir la referencia al documento
  const docRef = doc(db, ...pathSegments, documentId);
  const docSnapshot = await getDoc(docRef);
  return docSnapshot.exists(); // Devuelve true si el documento existe
};

// Función genérica para obtener un documento específico por su ID dentro de una colección
export const getDocumentById = async (collectionPath, documentId) => {
  try {
    // Descomponer la ruta de la colección en segmentos
    const pathSegments = collectionPath.split('/');

    // Construir la referencia al documento
    const docRef = doc(db, ...pathSegments, documentId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      console.log('No matching document found.');
      return null;
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    throw new Error('Error al obtener el documento');
  }
};


// Función para eliminar un documento de Firestore
export const deleteDocument = async (collectionPath, documentId) => {
  // Descomponer la ruta de la colección en segmentos
  const pathSegments = collectionPath.split('/');

  // Construir la referencia al documento
  const docRef = doc(db, ...pathSegments, documentId);

  // Eliminar el documento
  await deleteDoc(docRef);
};