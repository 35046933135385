import React from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminStoreButton = ({ onAdminClick }) => {

  return (
    // Button to handle store administration
    <IconButton
      onClick={onAdminClick}
      sx={{
        backgroundColor: 'white',
        boxShadow: 1,
        '&:hover': { backgroundColor: 'lightgrey' },
      }}
    >
      <SettingsIcon />
    </IconButton>
  );
};

export default AdminStoreButton;
