import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const ServicioEditButton = ({ onClick }) => {
  



  return (
    <>
    
   
    <Tooltip title="Editar Servicio" arrow>
      <Fab
        color="primary"
        aria-label="edit"
        style={{
          width: '20px',
          height:'20px',
          padding:'20px',
          backgroundColor: 'white',
        }}
        onClick={onClick} 
      >
        <EditIcon style={{ color: '#0000008a' }} />
      </Fab>
    </Tooltip>
  

</>
  );
};

export default ServicioEditButton;
