import React from 'react';
import { Skeleton } from '@mui/material';

const SlashSkeletonScreen = () => {
  return (
    <div style={styles.container}>
      {/* Navbar Skeleton */}
      <div style={styles.navbar}>
        <Skeleton variant="rectangular" height={80} width="100%" />
      </div>

      {/* Banner Skeleton */}
      <div style={styles.banner}>
        <Skeleton variant="rectangular" height={400} width="100%" />
      </div>


      {/* Productos Skeleton */}
      <div style={styles.products}>
        {[1, 2, 3, 4, 5, 6].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width="30%"
            height={200}
            style={styles.productItem}
          />
        ))}
      </div>

      <div style={styles.navbar}>
        <Skeleton variant="rectangular" height={80} width="100%" />
      </div>
      
      
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  navbar: {
    marginBottom: '20px',
  },
  banner: {
    marginBottom: '30px',
  },
  services: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  serviceItem: {
    marginRight: '1%',
  },
  products: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  productItem: {
    marginBottom: '20px',
  },
};

export default SlashSkeletonScreen;
