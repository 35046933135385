import React, { useState } from 'react';

const InfoProducto = ({descripcion ,color, size}) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    'Descripción',
    'Información adicional',
    'Envío',
    '¿Por qué comprar con nosotros?',
    'Reseñas',
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <div className="tabs_item">
            <p>
              {descripcion}
            </p>
            
          </div>
        );
      case 1:
        return (
          <div className="tabs_item">
            <table className="table table-striped">
              <tbody>
                <tr><td>Color:</td><td>{color}</td></tr>
                <tr><td>Size:</td><td>{size}</td></tr>
                <tr><td>Material:</td><td>100% Polyester</td></tr>
              </tbody>
            </table>
          </div>
        );
      case 2:
        return (
          <div className="tabs_item">
            <table className="table table-bordered">
              <tbody>
                <tr><td>Shipping</td><td>This item ships to USA</td></tr>
                <tr>
                  <td>Delivery</td>
                  <td>Estimated between 07/31/2024 and 08/05/2024</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case 3:
        return (
          <div className="tabs_item">
            <p>Here are 5 more great reasons to buy from us:</p>
            <ol>
              <li>Lorem Ipsum is simply dummy text of the printing industry.</li>
              <li>Lorem Ipsum has been the industry's standard dummy text.</li>
              <li>An unknown printer took a galley of type and scrambled it.</li>
              <li>Dummy text of the printing and typesetting industry.</li>
              <li>It has survived not only five centuries.</li>
            </ol>
          </div>
        );
      case 4:
        return (
          <div className="tabs_item">
            <div className="product-review-form">
              <h3>Customer Reviews</h3>
              <div className="review-comments">
                <div className="review-item">
                  <div className="rating">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="far fa-star"></i>
                  </div>
                  <h3>Good</h3>
                  <p>Lorem ipsum dolor sit amet...</p>
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input type="text" name="name" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" name="email" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Review Title</label>
                  <input type="text" name="title" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Review</label>
                  <textarea name="body" className="form-control"></textarea>
                </div>
                <button type="submit" className="btn btn-light">Submit Review</button>
              </form>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="col-lg-12 col-md-12">
      <div className="tab products-details-tab ">
        <ul className="tabs">
          {tabs.map((tab, index) => (
            <li key={index}>
              <a href="#" onClick={() => setActiveTab(index)}>
                <div className="dot"></div> {tab}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab_content min-h-[450px] ">{renderTabContent()}</div>
      </div>
    </div>
  );
};

export default InfoProducto;
