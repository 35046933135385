import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const BeneficiosAddButton = ({ onClick }) => {


  return (
    
    <>
       

      <Tooltip title="Agregar Beneficio" arrow>
        <Fab
          color="primary"
          aria-label="add"
          style={{
            position: 'relative',
            backgroundColor: 'white',
            bottom: '170px',
            right: '-905px',
          }}
          onClick={onClick} 
          >
          <AddIcon style={{ color: '#0000008a' }} />
        </Fab>
      </Tooltip>
      
    </>

  );
};

export default BeneficiosAddButton;
