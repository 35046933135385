
/*
lvasquez@MiVisualization.onmicrosoft.com
Generador d etarjetas https://developer.paypal.com/tools/sandbox/card-testing/
https://developer.paypal.com/api/rest/sandbox/card-testing/#link-testgeneratedcardnumbers



sandbox: https://sandbox.paypal.com
Sandbox Region: PE
Email: sb-e2mvk34449605@business.example.com
contraseña:  asA2Ch.>
App name : ReactSandbox
Client ID : ARDlXDm9-0nGfWpVpGCx6xdeMlzNLARduZ893Am82kIHb0-PQWPeKIDj0FJCVYk1mbSVafWCaBhk8S0h
Secret key 1 : EOt8j2aNb0WEcLUyVxfmuT7r1a7O_wmoxTJY_RGwpHipRo2Sbr7DL-vHUx8Qmxk0hl229N3q3xxxLoe2

*/

import React from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalButton from "./components/PaypalButton";
import { usePaypalHandlers } from "./hooks/usePaypalHandlers";

function PayPalManager({ clientID  , orderID  }) {
  // Asegúrate de recibir los parámetros `clientID`, `orderID` y opcionalmente `buttonStyle`.
  const layout = "vertical";
  const color = "gold";
  const shape = "rect";
  const label = "paypal";
  
  const { handleSuccess, handleError } = usePaypalHandlers();

  const initialOptions = {
        "client-id": clientID, // ID de cliente de sandbox
        "enable-funding": "", // Habilitar Venmo
        "disable-funding": "", // No deshabilitar ninguna opción específica
        "buyer-country": "US", // País del comprador
        currency: "USD", // Moneda USD
        "data-page-type": "product-details", // Tipo de página: detalles del producto
        components: "buttons", // Componentes que se cargan: botones
        "data-sdk-integration-source": "developer-studio", // Fuente de integración
  };
  


  return (
  	<div className="mt-2 mb-2" >
	    <PayPalScriptProvider  options={initialOptions} >
	      <PaypalButton 
		        orderID={orderID}
		        layout={layout}
		        color={color}
		        shape={shape}
		        label={label}
		        onSuccess={handleSuccess}
		        onError={handleError}
	      />
	    </PayPalScriptProvider>
    </div>
  );
}

export default PayPalManager;
