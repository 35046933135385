// src/components/NewCustomer.js
import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification,signOut  } from 'firebase/auth';
import { auth } from '../../firebaseConfig';  // Asegúrate de importar tu configuración de Firebase
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, InputAdornment, IconButton,Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'; 

const NewCustomer = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [fieldErrors, setFieldErrors] = useState({ email: '', password: '', confirmPassword: '' });
  const [generalError, setGeneralError] = useState('');
  //const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });


  const handleClickOpen = () => {
    setOpen(true);
  };

  // Cerrar el modal
  const handleClose = () => {
    setOpen(false);
    setFormData({ email: '', password: '', confirmPassword: '' });
    setFieldErrors({ email: '', password: '', confirmPassword: '' });
    setGeneralError('');
    setSuccessMessage('');
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const validateField = (name, value) => {
    switch (name) {
      case 'email':
        if (!value) {
          return 'El correo electrónico es obligatorio.';
        } else if (!emailRegex.test(value)) {
          return 'El correo electrónico no es válido.';
        } else {
          return '';
        }
      case 'password':
        if (!value) {
          return 'La contraseña es obligatoria.';
        } else if (!passwordRegex.test(value)) {
          return 'La contraseña debe tener al menos 8 caracteres, incluir mayúsculas, minúsculas y caracteres especiales.';
        } else {
          return '';
        }
      case 'confirmPassword':
        if (!value) {
          return 'Por favor, confirma tu contraseña.';
        } else if (value !== formData.password) {
          return 'Las contraseñas no coinciden.';
        } else {
          return '';
        }
      default:
        return '';
    }
  };

  // Manejar el cambio en los campos de entrada
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validar el campo actual
    const errorMessage = validateField(name, value);
    setFieldErrors((prev) => ({ ...prev, [name]: errorMessage }));

    // Si cambiamos la contraseña, también validar la confirmación
    if (name === 'password' && formData.confirmPassword) {
      const confirmError = validateField('confirmPassword', formData.confirmPassword);
      setFieldErrors((prev) => ({ ...prev, confirmPassword: confirmError }));
    }
  };

  // Función para manejar el registro
  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError('');

    // Validar todos los campos antes de enviar
    const emailError = validateField('email', formData.email);
    const passwordError = validateField('password', formData.password);
    const confirmPasswordError = validateField('confirmPassword', formData.confirmPassword);

    setFieldErrors({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    // Verificar si hay algún error
    if (emailError || passwordError || confirmPasswordError) {
      setGeneralError('Por favor, corrige los errores en el formulario.');
      return;
    }

    setLoading(true); // Establecer estado de carga

    try {
      // Crear cuenta con Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
      console.log("user",user)
      await sendEmailVerification(user);
      await signOut(auth);
      setSuccessMessage('Cuenta creada exitosamente. Por favor, verifica tu correo electrónico antes de iniciar sesión.');
      //await signInWithEmailAndPassword(auth, formData.email, formData.password);
      //console.log('Usuario creado y autenticado con éxito');
      //navigate('/admi_tienda'); // Redirigir a la página de administración
    } catch (error) {
      console.error('Error al crear la cuenta o iniciar sesión:', error.message);

      // Manejar errores específicos
      switch (error.code) {
        case 'auth/email-already-in-use':
          setGeneralError('Este correo ya está en uso. Intenta con otro.');
          break;
        case 'auth/invalid-email':
          setGeneralError('El correo electrónico no es válido.');
          break;
        case 'auth/weak-password':
          setGeneralError('La contraseña es muy débil. Debe tener al menos 6 caracteres.');
          break;
        default:
          setGeneralError('Ocurrió un error. Por favor, inténtalo nuevamente.');
      }
    } finally {
      setLoading(false); // Resetear el estado de carga
    }
  };

  const handleTogglePasswordVisibility = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
  <div className="new-customer-content ">
    <div className="section-title bg-white/80 rounded-lg shadow-lg backdrop-blur-sm">
      <h2>
        <span className="dot"></span> Nuevo cliente
      </h2>
    </div>
    <span className="text-white text-left" >Crear una cuenta</span>
    <p className="text-white text-left">
      Regístrese para obtener una cuenta gratuita en nuestra tienda. El registro es rápido y sencillo. 
      Le permite poder realizar pedidos en nuestra tienda. Para comenzar a comprar, haga clic en registrarse.
    </p>
    <div className="flex justify-start">
      <button className="btn btn-light" onClick={handleClickOpen}>
        Create A Account
      </button>
    </div>

    {/* Modal de registro */}
    <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <DialogTitle>Crear cuenta</DialogTitle>
          <DialogContent>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            {generalError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {generalError}
              </Alert>
            )}
            <TextField
              label="Correo electrónico"
              type="email"
              fullWidth
              variant="filled"
              margin="normal"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ backgroundColor: 'white' }}
              required
              error={Boolean(fieldErrors.email)}
              helperText={fieldErrors.email}
              disabled={successMessage !== ''}

            />
            <TextField
              label="Contraseña"
              type={showPassword.password ? 'text' : 'password'}
              fullWidth
              variant="filled"
              margin="normal"
              name="password"
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleTogglePasswordVisibility('password')} edge="end">
                      {showPassword.password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ backgroundColor: 'white' }}
              required
              error={Boolean(fieldErrors.password)}
              helperText={fieldErrors.password || 'Mínimo 8 caracteres, con mayúsculas, minúsculas y caracteres especiales.'}
              disabled={successMessage !== ''}

            />
            <TextField
              label="Repetir Contraseña"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              fullWidth
              variant="filled"
              margin="normal"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleTogglePasswordVisibility('confirmPassword')} edge="end">
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ backgroundColor: 'white' }}
              required
              error={Boolean(fieldErrors.confirmPassword)}
              helperText={fieldErrors.confirmPassword}
              disabled={successMessage !== ''}

            />
            {generalError && (
              <div style={{ color: 'red', marginTop: '10px', border: '1px solid red', padding: '10px', borderRadius: '4px' }}>
                {generalError}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? 'Creando cuenta...' : 'Crear cuenta'}
            </Button>
          </DialogActions>
        </form>
    </Dialog>
  </div>
  );
};
export default NewCustomer;
