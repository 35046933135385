import React from 'react';
import { Box } from '@mui/material';

const SkeletonLoader = () => {
  return (
    <Box
      sx={{
        width: '359px', // Ancho del skeleton
        height: '220px', // Altura del skeleton
        backgroundColor: '#f0f0f0', // Color del skeleton
        borderRadius: '4px', // Bordes redondeados
        animation: 'pulse 1.5s infinite', // Animación de carga
        '@keyframes pulse': {
          '0%': { opacity: 1 },
          '50%': { opacity: 0.5 },
          '100%': { opacity: 1 },
        },
      }}
    />
  );
};

export default SkeletonLoader;
