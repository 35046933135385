import React from 'react';

const TerminosServicio = () => {
  return (
    <div className="terms-of-service">
      <h1>Términos de Servicio</h1>
      
      <p><strong>Última actualización:</strong> 3 de diciembre de 2024</p>
      
      <h2>1. Aceptación de los Términos</h2>
      <p>
        Al acceder y utilizar nuestra plataforma de gestión de tiendas online ("la Plataforma"), aceptas cumplir con estos Términos de Servicio y con nuestra Política de Privacidad. Si no estás de acuerdo con alguno de estos términos, no utilices la Plataforma.
      </p>
      
      <h2>2. Uso de la Plataforma</h2>
      <p>
        Te otorgamos una licencia limitada, no exclusiva y no transferible para utilizar nuestra Plataforma con fines legítimos. No podrás utilizar la Plataforma para realizar actividades ilegales, fraudulentas o perjudiciales para otros usuarios.
      </p>
      
      <h2>3. Responsabilidad</h2>
      <p>
        La Plataforma se proporciona "tal cual", sin garantías de ningún tipo, ya sea explícita o implícita. No nos hacemos responsables de los daños directos, indirectos, incidentales o consecuentes que puedan surgir del uso de la Plataforma.
      </p>
      
      <h2>4. Propiedad Intelectual</h2>
      <p>
        Todos los derechos de propiedad intelectual relacionados con el diseño, contenido, y funcionalidad de la Plataforma son propiedad exclusiva de [Nombre de tu empresa]. No puedes copiar, modificar o distribuir ningún contenido de la Plataforma sin nuestra autorización explícita.
      </p>
      
      <h2>5. Modificaciones a los Términos</h2>
      <p>
        Nos reservamos el derecho de modificar estos Términos de Servicio en cualquier momento. Las modificaciones serán efectivas al momento de su publicación en la Plataforma.
      </p>

      <h2>6. Ley Aplicable</h2>
      <p>
        Estos Términos de Servicio se regirán e interpretarán de acuerdo con las leyes del país en el que se encuentre registrada nuestra empresa. Cualquier disputa será resuelta en los tribunales competentes de dicha jurisdicción.
      </p>
      
      <h2>7. Contacto</h2>
      <p>
        Si tienes alguna pregunta sobre estos Términos de Servicio, por favor contáctanos a: [correo@empresa.com]
      </p>
    </div>
  );
};

export default TerminosServicio;
