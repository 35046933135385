// src/App.js
import React from 'react';
import TiendaManager from '../components/Tienda/TiendaManager';
import LogoPreview from '../components/Logo/components/LogoPreview';

const Tiendas = () => (
    <div className="w-screen min-h-screen css-selector pb-10 pt-4">

      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
              <LogoPreview logo="assets2/img/logo/logominiblanco.png" width="250" height="80" />
          </div>
        </div>
      </div>
      <TiendaManager />
    </div>
);

export default Tiendas;
