import LZString from 'lz-string';

const decompressBeneficio = (compressedData) => {
  try {
    const decompressedString = LZString.decompressFromBase64(compressedData);
    if (!decompressedString) {
      throw new Error('Los datos descomprimidos son nulos o inválidos.');
    }

    const decompressedData = JSON.parse(decompressedString);
    console.log('Datos descomprimidos:', decompressedData);
    return decompressedData;
  } catch (error) {
    console.error('Error al descomprimir los datos:', error);
    return [];
  }
};

export default decompressBeneficio;
