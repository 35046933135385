import React, { useState } from 'react';
import SkeletonLoader from './SkeletonLoader';

const ItemTiendaPreview = ({ imagen, nombre, onTiendaClick, onAdminClick }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => setLoading(false);

  return (
    <div className="woocommerce-sidebar-area">
    <div className="collapse-widget aside-trending-widget">          
    <div
      className="aside-trending-products  rounded-md border-3 !border-solid border-white/30 hover:!border-blue-400 group" 
      onClick={onTiendaClick}
      style={{ position: 'relative', cursor: 'pointer' }}
    >
      {loading && <SkeletonLoader width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0 }} />}
      <img
        src={imagen}
        alt={`Imagen de la tienda ${nombre}`}
        onLoad={handleImageLoad}
        style={{
          display: loading ? 'none' : 'block',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      
        <div className="absolute left-0 right-0 bottom-1 group-hover:bottom-4 bg-white/20 group-hover:bg-[#ffffffb5] rounded-md shadow-lg backdrop-blur-sm border border-white/30 text-white group-hover:!text-black font-bold p-3 ml-2 mr-2 transition-all duration-500">
           <h4><a href="#"
           onClick={(e) => {
              e.stopPropagation();
              onAdminClick();
           }}
           style={{
              position: 'relative',
              textTransform: 'lowercase'
           }}
            > {nombre}</a></h4>
        </div>
      
    
    </div>
    </div>
    </div>
  );
};

export default ItemTiendaPreview;
