import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { auth } from '../../../firebaseConfig';

const BeneficiosEditButton = ({ onClick }) => {
  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);


  return (
    <>
    
    {user && (
    <Tooltip title="Editar Beneficio" arrow>
      <Fab
        color="primary"
        aria-label="edit"
        style={{
          width: '20px',
          height:'20px',
          padding:'20px',
          backgroundColor: 'white',
        }}
        onClick={onClick} 
      >
        <EditIcon style={{ color: '#0000008a' }} />
      </Fab>
    </Tooltip>
  )}

</>
  );
};

export default BeneficiosEditButton;
