import React, { useEffect, useState } from 'react';
import { doc, getDocFromCache, getDocFromServer } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useParams } from 'react-router-dom';
import LZString from 'lz-string';
import useStoreName from '../hooks/useStoreName';
import { getDocumentById } from '../utils/firebaseUtils';
import MetaTags from '../components/Producto/utils/MetaTags';

const ProductoShare = () => {
  const { storeName } = useStoreName();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [producto, setProducto] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!storeName || !id) return;

      try {
        const collectionPath = `${storeName}/inventario/productos`;
        const productData = await getDocumentById(collectionPath, id);

        if (!productData) {
          throw new Error('Producto no encontrado');
        }

        let additionalData = {};
        if (productData.data) {
          const decompressedData = LZString.decompressFromBase64(productData.data);
          additionalData = JSON.parse(decompressedData);
        }

        setProducto({
          id: productData.id,
          name: productData.name,
          productType: productData.productType,
          ...additionalData,
        });
      } catch (err) {
        console.error('Error al cargar los datos:', err);
        setError(`Error al obtener los datos: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeName, id]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (!producto) return <div>No se encontró el producto.</div>;

  return (
    <div>
      <MetaTags
        title={producto.name}
        description={producto.description}
        image={producto.images?.[0]}
        imageAlt={producto.name}
        url={producto.url ?? (typeof window !== 'undefined' ? window.location.href : '')}
        type="article"
        author={storeName}
        keywords={producto.keywords ?? 'SEO, optimización, tráfico orgánico, marketing digital'}
        twitterSite={producto.twitterSite ?? "@tusitio"}
        twitterCreator={producto.twitterCreator ?? "@juanperez"}
        publishedTime={producto.publishedTime ?? '2023-10-01T12:00:00+00:00'}
        modifiedTime={producto.modifiedTime ?? '2023-10-05T15:30:00+00:00'}
        section={producto.section ?? 'Marketing Digital'}
        tags={producto.tags ?? 'SEO,Marketing,Tráfico Orgánico'}
        appleTouchIcon={producto.appleTouchIcon ?? '/icons/apple-touch-icon.png'}
        msTileImage={producto.msTileImage ?? '/icons/mstile-150x150.png'}
        msTileColor={producto.msTileColor ?? '#2b5797'}
        googleSiteVerification={producto.googleSiteVerification ?? 'tu_codigo_de_verificacion'}
        facebookAppId={producto.facebookAppId ?? 'tu_facebook_app_id'}
      />
      <h1>{producto.name}</h1>
      <h2>{producto.description}</h2>
    </div>
  );
};

export default ProductoShare;
