// src/components/FooterWidget.js
import React from 'react';

const FooterWidget = ({ title, content, links, style }) => (
  <div className="col-lg-3 col-md-6 col-sm-6" style={{ textAlign: 'left', ...style }}>
    <div className="single-footer-widget">
      {title && <h3>{title}</h3>}
      {content}
      {links && (
        <ul className={title ? 'quick-links' : 'information-links'}>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.href}>{link.text}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

export default FooterWidget;
