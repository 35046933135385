import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Stack, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import compressNavbar from "../utils/compressNavbar"; // Reutilizamos la lógica de compresión

const NavbarForm = ({ storeName , open, onClose, onSave, currentNavbar }) => {
  
  const [items, setItems] = useState([]);

  // Sincronizar con los datos actuales del Navbar
  useEffect(() => {
    if (currentNavbar) {
      setItems(currentNavbar.items || []);
    }
  }, [currentNavbar]);

  // Manejar el cambio en los campos
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;
    setItems(updatedItems);
  };

  // Agregar un nuevo elemento
  const handleAddItem = () => {
    setItems([...items, { label: "", type: "link", href: "" }]);
  };

  // Eliminar un elemento
  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  // Guardar los cambios
  const handleSave = () => {
    const compressedData = compressNavbar({ items });
    onSave(compressedData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Editar Navbar</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {items.map((item, index) => (
            <Stack key={index} direction="row" spacing={2} alignItems="center">
              <TextField
                label="Texto del ítem"
                value={item.label}
                onChange={(e) => handleItemChange(index, "label", e.target.value)}
                fullWidth
              />
              <TextField
                label="Destino (URL)"
                value={item.href}
                onChange={(e) => handleItemChange(index, "href", e.target.value)}
                fullWidth
              />
              <TextField
                label="Tipo"
                value={item.type}
                onChange={(e) => handleItemChange(index, "type", e.target.value)}
                select
                SelectProps={{ native: true }}
                fullWidth
              >
                <option value="link">Link</option>
                <option value="button">Button</option>
              </TextField>
              <IconButton onClick={() => handleRemoveItem(index)}>
                <Delete />
              </IconButton>
            </Stack>
          ))}
          <Button variant="outlined" onClick={handleAddItem} startIcon={<Add />}>
            Agregar ítem
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NavbarForm;
