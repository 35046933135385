import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import BeneficiosForm from './components/BeneficiosForm';
import BeneficiosAddButton from './components/BeneficiosAddButton';
import BeneficiosPreview from './components/BeneficiosPreview';
import decompressBeneficios from './utils/decompressBeneficios';
import { saveToFirestore } from '../../utils/firebaseUtils';
import { doc, getDoc } from 'firebase/firestore'; 
import { db ,auth} from '../../firebaseConfig'; 
import LZString from 'lz-string';

const BeneficiosManager = ({ storeName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beneficiosArray, setBeneficiosArray] = useState([]);
  const [newBeneficio, setNewBeneficio] = useState({
    titulo: '',
    descripcion: '',
    porcentaje: 20, // Definir valor por defecto
    color: '#ffffff',
    colorStart: '#d9ddf1',
    colorEnd: 'rgba(233, 0, 0, 0%)',
    angle: '180deg', // Agregar angle al nuevo beneficio
  });
  const [beneficioToEdit, setBeneficioToEdit] = useState(null); 
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchBeneficios = async () => {
      try {
        const docRef = doc(db, storeName, 'config');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const compressedData = docSnap.data()?.beneficios || '';
          const decompressedData = decompressBeneficios(compressedData);

          const beneficios = Array.isArray(decompressedData) ? decompressedData : [decompressedData];
          const beneficiosConIds = beneficios.map((beneficio, index) => ({
            ...beneficio,
            id: beneficio.id || index + 1,
          }));

          setBeneficiosArray(beneficiosConIds);
        } else {
          setBeneficiosArray([]);
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
        setBeneficiosArray([]);
      }
    };

    fetchBeneficios();
  }, [storeName]);

  const handleSaveBeneficio = async (compressedBeneficioData) => {
    try {
      const decompressedData = decompressBeneficios(compressedBeneficioData);

      let updatedBeneficios;
      if (beneficioToEdit) {
        updatedBeneficios = beneficiosArray.map((beneficio) =>
          beneficio.id === beneficioToEdit.id ? decompressedData : beneficio
        );
      } else {
        updatedBeneficios = [...beneficiosArray, decompressedData];
      }

      setBeneficiosArray(updatedBeneficios);

      const updatedCompressedData = LZString.compressToBase64(JSON.stringify(updatedBeneficios));
      await saveToFirestore(storeName, 'config', { beneficios: updatedCompressedData });

      setNewBeneficio({
        titulo: '',
        descripcion: '',
        porcentaje: 20,
        color: '#ffffff',
        colorStart: '#d9ddf1',
        colorEnd: 'rgba(233, 0, 0, 0%)',
        angle: '180deg', // Restablecer el ángulo después de guardar
      });
      setBeneficioToEdit(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al guardar el beneficio:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBeneficio((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditBeneficio = (beneficio) => {
    setNewBeneficio({ ...beneficio });
    setBeneficioToEdit(beneficio);
    setIsModalOpen(true);
  };

  const handleDeleteBeneficio = async (beneficioId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este beneficio?')) {
      try {
        const updatedBeneficios = beneficiosArray.filter(
          (beneficio) => beneficio.id !== beneficioId
        );

        setBeneficiosArray(updatedBeneficios);

        const updatedCompressedData = LZString.compressToBase64(JSON.stringify(updatedBeneficios));
        await saveToFirestore(storeName, 'config', { beneficios: updatedCompressedData });
      } catch (error) {
        console.error('Error al eliminar el beneficio:', error);
      }
    }
  };

  return (
    <Box>
      <BeneficiosPreview 
        beneficiosArray={beneficiosArray}
        onEdit={handleEditBeneficio}
        onDelete={handleDeleteBeneficio}
        user={user}
      />

      {user && (
        <BeneficiosAddButton onClick={() => setIsModalOpen(true)} />
      )}

      <BeneficiosForm
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveBeneficio}
        newBeneficio={newBeneficio}
        handleInputChange={handleInputChange}
        storeName={storeName}
      />
    </Box>
  );
};

export default BeneficiosManager;
