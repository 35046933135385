import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import CardBeneficio from './../CardBeneficio';

const BeneficiosPreview = ({ beneficiosArray, onEdit, onDelete, width = 300, height = 200, user }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (beneficiosArray && beneficiosArray.length > 0) {
      // Simulamos un retraso de 1.5 segundos para la carga
      const timer = setTimeout(() => setLoading(false), 1500);
      return () => clearTimeout(timer); // Limpiar el temporizador cuando se desmonte o cambien los beneficios
    } else {
      setLoading(false); // Si no hay beneficios, directamente quitamos el loading
    }
  }, [beneficiosArray]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: height,
        padding: 2,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
      <h1
        className="text-2xl font-semibold my-5"
        style={{ color: '#255ce0', fontSize: '35px', fontWeight: '800' }}
      >
        Beneficios
      </h1>

      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: '1300px',
          margin: '0 auto',
          width: '100%',
        }}
      >
        {/* Si estamos cargando, mostramos SkeletonLoaders */}
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <SkeletonLoader width={width} height={height} />
            </Grid>
          ))
        ) : beneficiosArray.length === 0 ? (
          // Si no hay beneficios, mostramos un mensaje
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'darkgrey',
              fontSize: '14px',
              textAlign: 'center',
              width: '100%',
              height: '100%',
              paddingTop: '5%',
              paddingBottom: '5%',
            }}
          >
            No hay beneficios para mostrar
          </Box>
        ) : (
          // Si hay beneficios, mostramos las tarjetas
          beneficiosArray.map((beneficio) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={beneficio.id || beneficio.titulo}>
              <CardBeneficio
                id={beneficio.id}
                titulo={beneficio.titulo}
                descripcion={beneficio.descripcion}
                porcentaje={beneficio.porcentaje}
                color={beneficio.color}
                colorStart={beneficio.colorStart || ''}
                colorEnd={beneficio.colorEnd || ''}
                angle={beneficio.angle || '180deg'} // Asegurándonos de que el ángulo esté presente
                onEdit={() => onEdit(beneficio)} // Función para editar el beneficio
                onDelete={() => onDelete(beneficio.id)} // Función para eliminar el beneficio
                user={user}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default BeneficiosPreview;
