import React, {useState} from 'react';
import { Modal, Box, TextField, Button, Typography, Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../firebaseConfig';


const BannerEditorModal = ({ open, handleClose, bannerData, handleChange, handleSubmit, handleImageChange, loading, setLoading,bannerType }) => {
  
  const [error, setError] = useState(null);
  const generateBannerDescription = async () => {
    setLoading(true);
    setError(null);

    const generateDescriptionFunction = httpsCallable(functions, 'generateBannerDescriptionWithAI'); // La función de Firebase

    try {
      const result = await generateDescriptionFunction({
        bannerData: bannerData,  // Datos actuales del banner
        idioma: 'español',        // El idioma para la descripción
      });

      const descripcionPorAI = result.data.descripcionPorAI;

      // Actualizar el estado con los resultados generados por la IA
      handleChange({
        target: {
          name: 'description',
          value: descripcionPorAI.description,
        },
      });

      handleChange({
        target: {
          name: 'seo',
          value: {
            metaTitle: descripcionPorAI.seo.metaTitle,
            metaDescription: descripcionPorAI.seo.metaDescription,
            keywords: descripcionPorAI.seo.keywords,
            altText: descripcionPorAI.seo.altText,
            productUrl: descripcionPorAI.seo.productUrl,
          },
        },
      });

      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  
  
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          maxHeight: '94vh',
          overflowY: 'auto',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Editar Banner
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            {/* Sección de Subtítulo */}
            {bannerType === 'MainBanner' && (
              <>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Subtítulo"
                    name="subtitle"
                    value={bannerData.subtitle}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Color del Subtítulo</InputLabel>
                  <input
                    type="color"
                    name="subtitleColor"
                    value={bannerData.subtitleColor}
                    onChange={handleChange}
                    style={{ width: '100%', height: '40px', border: 'none' }}
                  />
                </Grid>
              </>
            )}
            {/* Sección de Título 1*/}
            {bannerType === 'MainBanner2' && (
              <>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Título1"
                    name="title1"
                    value={bannerData.title1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel>Color del Título1</InputLabel>
                  <input
                    type="color"
                    name="titleColor1"
                    value={bannerData.titleColor1}
                    onChange={handleChange}
                    style={{ width: '100%', height: '40px', border: 'none' }}
                  />
                </Grid>
              </>
            )}
            {/* Sección de Título */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Título"
                name="title"
                value={bannerData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Color del Título</InputLabel>
              <input
                type="color"
                name="titleColor"
                value={bannerData.titleColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {/* Sección de Descripción */}
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Descripción"
                name="description"
                value={bannerData.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Color de la Descripción</InputLabel>
              <input
                type="color"
                name="descriptionColor"
                value={bannerData.descriptionColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={generateBannerDescription}
                fullWidth
                disabled={loading}
              >
                {loading ? 'Generando Descripción...' : 'Generar Descripción con IA'}
              </Button>
            </Grid>

            {/* Configuración del Botón */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Texto del Botón"
                name="buttonText"
                value={bannerData.buttonText}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="URL de Acción del Botón"
                name="buttonAction"
                value={bannerData.buttonAction}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Color del Botón</InputLabel>
              <input
                type="color"
                name="buttonColor"
                value={bannerData.buttonColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>
            <Grid item xs={2}>
              <InputLabel>Color del Texto del Botón</InputLabel>
              <input
                type="color"
                name="buttonTextColor"
                value={bannerData.buttonTextColor}
                onChange={handleChange}
                style={{ width: '100%', height: '40px', border: 'none' }}
              />
            </Grid>

            {bannerType == 'MainBanner2' && (
              <>
                {/* Configuración del Botón 2*/}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Texto del Botón 2"
                    name="buttonText2"
                    value={bannerData.buttonText2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="URL de Acción del Botón 2"
                    name="buttonAction2"
                    value={bannerData.buttonAction2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Color del Botón</InputLabel>
                  <input
                    type="color"
                    name="buttonColor2"
                    value={bannerData.buttonColor2}
                    onChange={handleChange}
                    style={{ width: '100%', height: '40px', border: 'none' }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Color del Texto del Botón 2</InputLabel>
                  <input
                    type="color"
                    name="buttonTextColor2"
                    value={bannerData.buttonTextColor2}
                    onChange={handleChange}
                    style={{ width: '100%', height: '40px', border: 'none' }}
                  />
                </Grid>
              </>
            )}

            {/* Tipo de Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tipo de Banner</InputLabel>
                <Select
                  label="Tipo de Banner"
                  name="bannerType"
                  value={bannerData.bannerType}
                  onChange={handleChange}
                >
                  <MenuItem value="MainBanner">Diseño 1</MenuItem>
                  <MenuItem value="MainBanner2">Diseño 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Subir Imagen */}
            <Grid item xs={12}>
              <Button variant="contained" component="label" fullWidth>
                Subir Imagen de fondo
                <input type="file" hidden accept="image/*" onChange={(e) => handleImageChange(e, 'image')} />
              </Button>
            </Grid>

            {bannerType == 'MainBanner2' && (
              <>
                <Grid item xs={12}>
                  <Button variant="contained" component="label" fullWidth>
                    Subir Imagen secundaria
                    <input type="file" hidden accept="image/*" onChange={(e) => handleImageChange(e, 'image2')} />
                  </Button>
                </Grid>
              </>
            )}

            {/* Botón para guardar */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading}
              >
                {loading ? 'Guardando...' : 'Guardar Cambios'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default BannerEditorModal;
