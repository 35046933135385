import React from 'react';
import { Box } from '@mui/material';

const SkeletonLoader = ({ width, height ,style, children}) => (
  <Box
    sx={{
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: 'lightgrey',
      borderRadius: '4px',
      animation: 'pulse 1.5s infinite',
      display: 'flex', // Permitir posicionar elementos hijos
      alignItems: 'center',
      justifyContent: 'center',
      '@keyframes pulse': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 1 },
      },
      ...style, 
    }}
  >
    {children}
  </Box>
);

export default SkeletonLoader;
