import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const NavbarEditButton = ({ onClick }) => (
  <IconButton
    size="small"
    onClick={onClick}
    sx={{
      position: "absolute", // Posición absoluta
      top: "32px", // Ajusta la posición desde arriba
      right: "16px", // Ajusta la posición desde la derecha
      backgroundColor: "white",
      boxShadow: 1,
      "&:hover": { backgroundColor: "lightgrey" },
    }}
  >
    <EditIcon  />
  </IconButton>
);

export default NavbarEditButton;
