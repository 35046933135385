// src/App.js
import React from 'react';
import TopPanel from '../components/Top/TopPanel';
import TopHeader from '../components/Top/TopHeader';
import Navbar from '../components/Top/Navbar/Navbar';
import MainBanner from '../components/MainBanner';
import OfferSection from '../components/OfferSection';
import FacilityArea from '../components/FacilityArea';
import Footer from '../components/Footer';

import LoginArea from '../components/Login/LoginArea';

const MarketTema1 = () => (
    <div className="w-screen min-h-screen css-selector" >
      <div className="pt-[5%] pb-10">
         <LoginArea />
      </div>
    </div>
);

export default MarketTema1;
