import React from 'react';
import ServicioEditButton from './components/ServicioEditButton';
import ServicioDeleteButton from './components/ServicioDeleteButton';

const CardServicio = ({ id, titulo, descripcion, color, icono, onEdit, onDelete ,user }) => {
  return (  
    
    <div
      className="rounded-lg flex items-start flex-col gap-3 transition-transform transition-shadow duration-300 hover:shadow-card hover:-translate-y-2"
      style={{
        padding: '40px',
        borderRadius: '20px',
      }}
    >
      <div className="flex justify-between w-full">
        <div
          style={{
            backgroundColor: color,
            padding: '15px',
            width: '60px',
            height: '60px',
            borderRadius: '12px',
          }}
          className="flex justify-center items-center bg-[#004cd3]"
        >
          <img src={icono} alt="Ícono" style={{ width: 'auto', height: 'auto' }} />
        </div>


        <div className="flex gap-2">
            
        {user && (
          <>
            <ServicioEditButton onClick={() => onEdit({ id, titulo, descripcion, color, icono })} />
            <ServicioDeleteButton onClick={() => onDelete(id)} />

          </>
          )}
            
        </div>
      </div>

    
      <div className='flex flex-col gap-3'>
        <p className="text-left font-bold text-xl tex-[20px] text-black">{titulo}</p>
        <p className="text-left font-normal text-base text-black max-h-[100px] min-h-[100px] line-clamp-4">
          {descripcion}
        </p>
      </div>
    </div>
  );
};

export default CardServicio;
