import React, { useEffect, useState } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import LZString from 'lz-string';
import MainBanner from './components/MainBanner';
import MainBanner2 from './components/MainBanner2';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

const BannerManager = ({storeName,compressedBanner}) => {
 
  

    const refreshStoreConfig = async () => {
        window.location.reload();
    };

    let bannerComponent;

    if (compressedBanner) {
        const decompressedData = LZString.decompressFromBase64(compressedBanner);
        if (decompressedData) {
            const bannerData = JSON.parse(decompressedData);
            const bannerType = bannerData.bannerType;
            
            if (bannerType === 'MainBanner') {
                bannerComponent = <MainBanner compressedBanner={compressedBanner} refreshStoreConfig={refreshStoreConfig} storeName={storeName}/>;
            } else if (bannerType === 'MainBanner2') {
                bannerComponent = <MainBanner2 compressedBanner={compressedBanner} refreshStoreConfig={refreshStoreConfig} storeName={storeName}/>;
            } else {
                bannerComponent = <MainBanner compressedBanner={compressedBanner} refreshStoreConfig={refreshStoreConfig} storeName={storeName}/>;
            }
        } else {
            console.error('Error al descomprimir los datos del banner.');
            bannerComponent = <MainBanner compressedBanner={compressedBanner} refreshStoreConfig={refreshStoreConfig} storeName={storeName}/>;
        }
    } else /*if (!loading)*/ {
        bannerComponent = <MainBanner compressedBanner={null} refreshStoreConfig={refreshStoreConfig} storeName={storeName}/>;
    }

 


    return (
        <>
            {bannerComponent}
        </>
        
    );
};

export default BannerManager;
