// src/components/ProductFormModal.js

import React, { useState,useEffect } from 'react';
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Chip,
  Stack,Autocomplete
} from '@mui/material';
import {  Select, MenuItem, InputLabel, FormControl , Divider} from '@mui/material';
import {
  Checkbox,
  FormControlLabel,Tooltip 
} from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import IconButton from '@mui/material/IconButton';
import { functions } from '../../../firebaseConfig'; // Asegúrate de que la ruta sea correcta
import { httpsCallable } from 'firebase/functions';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import tinycolor from 'tinycolor2';
import 'react-quill/dist/quill.snow.css';

const suggestedColors = [
  { code: '#FF5733', name: 'Rojo' },
  { code: '#33FF57', name: 'Verde' },
  { code: '#3357FF', name: 'Azul' },
  { code: '#000000', name: 'Negro' },
  { code: '#FFFFFF', name: 'Blanco' },
  { code: '#FFC300', name: 'Amarillo' },
  { code: '#C70039', name: 'Rojo Oscuro' },
  { code: '#900C3F', name: 'Borgoña' },
  { code: '#DAF7A6', name: 'Verde Claro' },
  { code: '#581845', name: 'Púrpura Oscuro' },
  { code: '#FF6F61', name: 'Salmón' },
  { code: '#8A2BE2', name: 'Violeta' },
  { code: '#5F9EA0', name: 'Verde Agua' },
  { code: '#D2691E', name: 'Marrón' },
  { code: '#FFD700', name: 'Dorado' },
];

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'], // Agrega botones de imagen y video
      ['code'],
    ],
    handlers: {
      code: function () {
        const htmlCode = prompt('Ingresa el código HTML del iframe:');
        if (htmlCode) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.clipboard.dangerouslyPasteHTML(range.index, htmlCode); // Inserta el HTML sin escapar
        }
      },
      image: function () {
        const url = prompt('Ingresa la URL de la imagen:');
        if (url) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', url); // Inserta la imagen como URL
        }
      },
      video: function () {
        const url = prompt('Ingresa la URL del video (YouTube, Vimeo, etc.):');
        if (url) {
          const quill = this.quill;
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'video', url); // Inserta el video como URL
        }
      },
    },
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
  'video',
];

const ProductFormModal = ({ open, handleClose, handleSubmit, productData = null }) => {


  const [formData, setFormData] = useState({
    id: '',
    name: '',
    price: '',
    oldPrice: '',
    vendor: '',
    availability: '',
    productType: '',
    paymentLink: '',
    colors: [],          // Lista de colores
    sizes: [],           // Lista de tamaños
    images: [],          // URLs de imágenes del producto
    description: '',     // Descripción detallada del producto
    seo: {               // Información SEO
      metaTitle: '',
      metaDescription: '',
      keywords: [],      // Lista de keywords SEO
      altText: [],       // Lista de textos alternativos para las imágenes
      productUrl: '',    // URL del producto
    },
    material: '',        // Material del producto
    careInstructions: '', // Instrucciones de cuidado del producto
    schema: {            // Esquema para Schema.org
      "@context": "https://schema.org",
      "@type": "Product",
      name: '',
      description: '',
      brand: {
        "@type": "Brand",
        name: '',
      },
      offers: {
        "@type": "Offer",
        url: '',
        priceCurrency: 'USD',
        price: '',
        availability: 'https://schema.org/InStock'
      }
    },
    currency: 'PEN',
    isOffer: false,
  });
  
  useEffect(() => {
    if (productData) {
      setFormData({
        id: productData.id || '',
        name: productData.name || '',
        price: productData.price || '',
        oldPrice: productData.oldPrice || '',
        vendor: productData.vendor || '',
        availability: productData.availability || '',
        productType: productData.productType || '',
        paymentLink: productData.paymentLink || '',
        colors: productData.colors || [],
        sizes: productData.sizes || [],
        images: productData.images || [],
        description: productData.description || '',
        seo: {
          metaTitle: productData.seo?.metaTitle || '',
          metaDescription: productData.seo?.metaDescription || '',
          keywords: productData.seo?.keywords || [],
          altText: productData.seo?.altText || [],
          productUrl: productData.seo?.productUrl || '',
        },
        material: productData.material || '',
        careInstructions: productData.careInstructions || '',
        schema: {
          "@context": productData.schema?.["@context"] || "https://schema.org",
          "@type": productData.schema?.["@type"] || "Product",
          name: productData.schema?.name || '',
          description: productData.schema?.description || '',
          brand: {
            "@type": productData.schema?.brand?.["@type"] || "Brand",
            name: productData.schema?.brand?.name || '',
          },
          offers: {
            "@type": productData.schema?.offers?.["@type"] || "Offer",
            url: productData.schema?.offers?.url || '',
            priceCurrency: productData.schema?.offers?.priceCurrency || 'USD',
            price: productData.schema?.offers?.price || '',
            availability: productData.schema?.offers?.availability || 'https://schema.org/InStock'
          }
        },
        currency: productData.currency || '',

      });
    } else {
      resetForm();
    }
  }, [productData, open]);


  const resetForm = () => {
      setFormData({
        id: '',
        name: '',
        price: '',
        oldPrice: '',
        vendor: '',
        availability: '',
        productType: '',
        paymentLink: '',
        colors: [],
        sizes: [],
        images: [],
        description: '',
        seo: {
          metaTitle: '',
          metaDescription: '',
          keywords: [],
          altText: [],
          productUrl: '',
        },
        material: '',
        careInstructions: '',
        schema: {
          "@context": "https://schema.org",
          "@type": "Product",
          name: '',
          description: '',
          brand: {
            "@type": "Brand",
            name: '',
          },
          offers: {
            "@type": "Offer",
            url: '',
            priceCurrency: 'USD',
            price: '',
            availability: 'https://schema.org/InStock'
          }
        },
        currency: 'PEN',
        isOffer: false,
      });
    };



  const storage = getStorage();
  const [currentColor, setCurrentColor] = useState('');
  const [currentSize, setCurrentSize] = useState('');
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [loading, setLoading] = useState(false);  // Para manejar el estado de carga
  const [error, setError] = useState(null);  // Para manejar errores
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  
  const toggleOffer = () => {
    setFormData((prevState) => ({
      ...prevState,
      isOffer: !prevState.isOffer,
      oldPrice: !prevState.isOffer ? prevState.oldPrice : '', // Limpia "Precio Anterior" si se desactiva.
    }));
  };

  const handleAddColor = () => {
    const isValidColor = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    if (isValidColor.test(currentColor)) {
      if (currentColor) {
        setFormData((prev) => ({
          ...prev,
          colors: [...prev.colors, currentColor],
        }));
        setCurrentColor('');
      }
    } else {
      alert('Por favor, ingresa un código de color válido.');
    }
  };

  const handleRemoveColor = (color) => {
    setFormData((prev) => ({
      ...prev,
      colors: prev.colors.filter((c) => c !== color),
    }));
  };

  const handleAddSize = () => {
    if (currentSize) {
      setFormData((prev) => ({
        ...prev,
        sizes: [...prev.sizes, currentSize],
      }));
      setCurrentSize('');
    }
  };

  const handleRemoveSize = (size) => {
    setFormData((prev) => ({
      ...prev,
      sizes: prev.sizes.filter((s) => s !== size),
    }));
  };

  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
    handleClose();
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleAddKeyword = () => {
    if (currentKeyword) {
      setFormData((prev) => ({
        ...prev,
        seo: {
          ...prev.seo,
          keywords: [...prev.seo.keywords, currentKeyword],
        },
      }));
      setCurrentKeyword('');
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setFormData((prev) => ({
      ...prev,
      seo: {
        ...prev.seo,
        keywords: prev.seo.keywords.filter((k) => k !== keyword),
      },
    }));
  };
  
  // Función para generar descripción con IA
  const generateProductDescription = async () => {
    setLoading(true);  // Estado de carga
    setError(null);  // Resetear error

    const generateDescriptionFunction = httpsCallable(functions, 'generateProductDescriptionWithAI');

    try {
 
      //const result = await generateDescriptionFunction({ producto: formData, idioma: 'español' }); 
      //console.log("descripcionPorImagen result",result); 
      //const descripcionPorImagen = result.descripcionPorImagen;
     
      
      //console.log("descripcionPorImagen.description",descripcionPorImagen);
      // Actualizar el formulario con los datos obtenidos
      /*setFormData((prev) => ({
        ...prev,
        description: descripcionPorImagen.description,
        seo: {
          ...prev.seo,
          metaTitle: descripcionPorImagen.seo.metaTitle,
          metaDescription: descripcionPorImagen.seo.metaDescription,
          keywords: descripcionPorImagen.seo.keywords,  // Actualizar keywords con los resultados obtenidos
          altText: descripcionPorImagen.seo.altText,
          productUrl: descripcionPorImagen.seo.productUrl
        },
        material: descripcionPorImagen.material,
        careInstructions: descripcionPorImagen.careInstructions,
      }));*/

      setLoading(false);  // Terminar el estado de carga
    } catch (err) {
      setError(err.message);
      setLoading(false);  // Terminar el estado de carga
    }
  };

  // Función para subir imágenes a Firebase Storage
  const uploadImageToStorage = async (file) => {
    const storageRef = ref(storage, `products/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          // Progreso de la carga (opcional)
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => resolve(downloadURL))
            .catch((error) => reject(error));
        }
      );
    });
  };

  // Manejar imágenes y subirlas a Firebase Storage
  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    setLoading(true); // Establecer el estado de carga a verdadero

    try {
      const imageUrls = await Promise.all(files.map((file) => uploadImageToStorage(file)));
      
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...imageUrls],
      }));

      setLoading(false); // Terminar el estado de carga
    } catch (error) {
      console.error('Error al cargar las imágenes:', error);
      setLoading(false); // Terminar el estado de carga
    }
  };
  
  const showAdvancedAlert = () => {
    alert('Próximamente');
  };
  

  const [isHtmlMode, setIsHtmlMode] = useState(false); 
  const [htmlContent, setHtmlContent] = useState('');
  const handleQuillChange = (value) => {
    setHtmlContent(value); // Actualiza el estado con el contenido HTML
  };
  

  const [selectedMethods, setSelectedMethods] = useState([]); // Métodos de pago seleccionados
  const handleMethodChange = (event) => {
    const { value } = event.target;
    setSelectedMethods(typeof value === 'string' ? value.split(',') : value); //falta actualizar en la data del front y cargar
  };


  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,  // Ajustado para que el modal ocupe toda la altura
          left: 0,  // Ajustado para que el modal ocupe toda la anchura
          width: '100%',  // El modal ocupará el 100% del ancho
          height: '100vh',  // El modal ocupará el 100% del alto de la pantalla
          overflowY: 'auto',  // Permitimos el desplazamiento vertical
          bgcolor: 'background.paper',
          borderRadius: 0,  // Elimina bordes redondeados si deseas
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',mb: 2 }}>
          <Typography variant="h6" >{productData ? 'Editar Producto' : 'Registrar Producto'}</Typography>
          <IconButton onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                      }}>
            <CloseIcon />
          </IconButton>
        </Box>

   

        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>

          <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            
            
            
            <Grid item xs={12}>
            <Grid container spacing={2} >
              {/* Configuración Producto */}
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                {/* Título */}
                <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                  Información Producto
                </Typography>

                {/* Divider */}
                <Box flex={1} marginLeft={2}>
                  <Divider />
                </Box>

                {/* Botón Avanzado */}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={showAdvancedAlert}
                  style={{ marginLeft: '16px' }} // Espaciado entre el Divider y el botón
                >
                  Avanzado
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nombre del Producto"
                  name="name"
                  value={formData.name}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 70) { // Límite de 70 caracteres
                      handleChange(e); // Actualiza el valor si está dentro del límite
                    }
                  }}
                  inputProps={{
                    maxLength: 70, // Límite máximo en el input
                  }}
                  helperText={`Máximo 70 caracteres (${formData.name.length}/70)`} // Muestra el progreso
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descripción Corta"
                  name="description"
                  value={formData.description}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 160) { // Límite de 160 caracteres
                      handleChange(e); // Actualiza si está dentro del límite
                    }
                  }}
                  multiline
                  rows={2}
                  inputProps={{
                    maxLength: 160, // Límite máximo en el input
                  }}
                  helperText={`Máximo 160 caracteres (${formData.description.length}/160)`} // Muestra el progreso
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Tipo de Producto"
                  name="productType"
                  value={formData.productType}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      handleChange(e); // Actualiza el valor si está dentro del límite
                    }
                  }}
                  inputProps={{
                    maxLength: 50, // Límite máximo en el input
                  }}
                  helperText={`Máximo 50 caracteres (${formData.productType.length}/50)`} // Mensaje de ayuda dinámico
                  required
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Vendedor"
                  name="vendor"
                  value={formData.vendor}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      handleChange(e); // Actualiza el valor si está dentro del límite
                    }
                  }}
                  inputProps={{
                    maxLength: 50, // Límite máximo en el input
                  }}
                  helperText={`Máximo 50 caracteres (${formData.vendor.length}/50)`} // Mensaje de ayuda dinámico
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Disponibilidad"
                  name="availability"
                  value={formData.availability}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      handleChange(e); // Actualiza el valor si está dentro del límite
                    }
                  }}
                  inputProps={{
                    maxLength: 50, // Límite máximo en el input
                  }}
                  helperText={`Máximo 50 caracteres (${formData.availability.length}/50)`} // Mensaje de ayuda dinámico
                />
              </Grid>

              
              {/* Editor de Stock */}
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Stock"
                  name="stock"
                  value={formData.stock}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Permite solo números válidos mayores a 0 o valores en blanco
                    if (/^\d*$/.test(value) || value === '') {
                      handleChange(e); // Actualiza el valor si es válido
                    }
                  }}
                  onInput={(e) => {
                    // Elimina caracteres no numéricos
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  type="text" // Cambiado a "text" para mayor control
                  inputProps={{
                    min: 1, // Mínimo permitido
                    step: 1, // Incrementos enteros
                  }}
                  error={
                    formData.stock !== '' && parseInt(formData.stock, 10) <= 0
                  } // Error solo si no está en blanco y es inválido
                  helperText={
                    formData.stock !== '' && parseInt(formData.stock, 10) <= 0
                      ? 'El stock debe ser un número mayor a 0'
                      : ''
                  } // Mensaje solo si no está en blanco y es inválido
                />
              </Grid>


              {/* Editor de Color */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    options={suggestedColors.map((option) => `${option.code} (${option.name})`)}
                    value={currentColor}
                    onInputChange={(event, newInputValue) => {
                      const colorCode = newInputValue.split(' ')[0]; // Solo toma el código (ej. #FF5733)
                      setCurrentColor(colorCode);
                    }}
                    sx={{
                      '& .MuiAutocomplete-inputRoot': {
                        paddingRight: '14px !important', // Ajusta el espacio para el ícono
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Agregar Color"
                        value={currentColor}
                        error={!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(currentColor) && currentColor !== ''}
                        helperText={!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(currentColor) && currentColor !== '' ? 'Debe ser un código de color válido (#RRGGBB o #RGB)' : ''}
                      
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton
                              variant="outlined"
                              color="primary"
                              onClick={handleAddColor}
                              disabled={!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(currentColor)}
                            >
                              <AddIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', // Ajusta según el tamaño disponible
                            gap: 1, // Espaciado entre elementos
                          }} >
                    {formData.colors.map((color, index) => (
                      <Chip
                        key={index}
                        label={color}
                        onDelete={() => handleRemoveColor(color)}
                        color= {tinycolor(color).isLight() ? '' : 'primary'}
                        sx={{
                          backgroundColor: color, // Color de fondo dinámico
                          color: tinycolor(color).isLight() ? '#000' : '#fff',
                          border: `1px solid ${tinycolor(color).darken(10).toString()}`,
                        }}
                      />
                    ))}
                  </Stack>
                </Grid>
                </Grid>
              </Grid>
              {/* Editor de Tamaño */}
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  {/* Input con Autocomplete para Tamaño */}
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={['Pequeño', 'Mediano', 'Grande', 'XL', 'XXL']} // Lista de tamaños sugeridos
                      value={currentSize}
                      onInputChange={(event, newInputValue) => {
                        setCurrentSize(newInputValue); // Actualiza el tamaño ingresado
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Agregar Tamaño"
                          value={currentSize}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <IconButton
                                variant="outlined"
                                color="primary"
                                onClick={handleAddSize}
                                disabled={!currentSize || formData.sizes.includes(currentSize)} // Desactiva si no hay valor o ya existe
                              >
                                <AddIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Lista de Chips para Tamaños Seleccionados */}
                  <Grid item xs={12}>
                    <Stack
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', // Ajusta para una distribución automática
                        gap: 1, // Espaciado entre Chips
                      }}
                    >
                      {formData.sizes.map((size, index) => (
                        <Chip
                          key={index}
                          label={size}
                          onDelete={() => handleRemoveSize(size)}
                          color="info" // Cambia según diseño
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>

              {/* Descripción Producto */}
              <Grid item xs={12} display="flex" justifyContent="flex-end">
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                {/* Título */}
                <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                  Descripción Producto
                </Typography>

                {/* Divider */}
                <Box flex={1} marginLeft={2}>
                  <Divider />
                </Box>

                {/* Botón Avanzado */}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsHtmlMode(!isHtmlMode)}
                >
                  {isHtmlMode ? 'Modo Visual' : 'Editar HTML'}
                </Button>
              </Grid>
              {/* Editor de HTML */}
              <Grid item xs={12} style={{ overflow: 'hidden', width: '100%' , height: '400px' }} >
                {isHtmlMode ? (
                  <Box>
                    <Typography variant="body1">Edición HTML:</Typography>
                    <TextField
                      value={htmlContent}
                      multiline
                      rows={10}
                      fullWidth
                      onChange={(e) => { setHtmlContent( e.target.value); }}
                    />
                  </Box>
                ) : (
                  <ReactQuill
                    theme="snow"
                    value={htmlContent}
                    onChange={handleQuillChange}
                    modules={modules}
                    formats={formats}
                    placeholder="Escribe aquí la descripción de tu producto..."
                    style={{ height: '300px',  width: '100%', overflow: 'none' }}
                  />
                )}
              </Grid>

            </Grid>
            </Grid>

            
            



          </Grid>
          </Grid>
          {/* Configuración de Precio */}
          <Grid item xs={12} md={6}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              {/* Configuración de Precio */}
              <Grid container spacing={2} >
              {/* Botón Avanzado */}
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                {/* Título */}
                <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                  Configuración de Precios
                </Typography>

                {/* Divider */}
                <Box flex={1} marginLeft={2}>
                  <Divider />
                </Box>

                {/* Botón Avanzado */}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={showAdvancedAlert}
                  style={{ marginLeft: '16px' }} // Espaciado entre el Divider y el botón
                >
                  Avanzado
                </Button>
              </Grid>
              {/* Tipo Moneda */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    name="currency"
                    value={formData.currency || 'PEN'}
                    onChange={handleChange}
                    label="Moneda"
                  >
                    <MenuItem value="PEN">Soles (PEN)</MenuItem>
                    <MenuItem value="USD">Dólar (USD)</MenuItem>
                    <MenuItem value="EUR">Euro (EUR)</MenuItem>
                    <MenuItem value="BRL">Real (BRL)</MenuItem>
                    <MenuItem value="ARS">Peso Argentino (ARS)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Precio */}
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label={`Precio (${formData.currency || 'PEN' })`} // Moneda dinámica
                  name="price"
                  type="number"
                  value={formData.price}
                  onChange={handleChange}
                  required
                />
              </Grid>
              {/* Oferta */}
              <Grid item xs={12} sm={4}>
                <Box display="flex" alignItems="center" gap={1}>
                  {/* Checkbox para Activar/Desactivar la Oferta */}
                  <Tooltip
                    title={formData.isOffer ? 'Desactivar oferta' : 'Activar oferta'} // Texto dinámico
                    arrow
                  >
                    <Checkbox
                      checked={formData.isOffer}
                      onChange={toggleOffer}
                      color="primary"
                    />
                  </Tooltip>
                  {/* Campo de Texto para Precio Anterior */}
                  <TextField
                    fullWidth
                    label={`Oferta (${formData.currency || 'PEN'})`}
                    name="oldPrice"
                    type="number"
                    value={formData.isOffer ? formData.oldPrice : ''} // Muestra vacío si no es oferta
                    onChange={handleChange}
                    disabled={!formData.isOffer} // Desactiva solo el campo, no el Checkbox
                  />
                </Box>
              </Grid>
              </Grid>
            </Grid>

            {/* Select for Payment Methods */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Métodos de Pago</InputLabel>
                <Select
                  multiple
                  value={selectedMethods}
                  onChange={handleMethodChange}
                  renderValue={(selected) => selected.join(', ')}
                  label="Métodos de Pago"
                >
                  <MenuItem value="link">
                    <Checkbox checked={selectedMethods.includes('link')} />
                    <ListItemText primary="Link" />
                  </MenuItem>
                  <MenuItem value="paypal">
                    <Checkbox checked={selectedMethods.includes('paypal')} />
                    <ListItemText primary="PayPal" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {selectedMethods.includes('link') && (
              <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Link de Pago"
                    name="paymentLink"
                    value={formData.paymentLink}
                    onChange={handleChange}
                  />
              </Grid>
            )}

           

            <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {/* Botón Avanzado */}
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                {/* Título */}
                <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                  Configuración de Seo
                </Typography>

                {/* Divider */}
                <Box flex={1} marginLeft={2}>
                  <Divider />
                </Box>

                {/* Botón Avanzado */}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={showAdvancedAlert}
                  style={{ marginLeft: '16px' }} // Espaciado entre el Divider y el botón
                >
                  Avanzado
                </Button>
              </Grid>
              {/* Editor de Palabras Clave */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Input con Autocomplete para Palabras Clave */}
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      options={['SEO', 'Marketing', 'E-commerce', 'Ventas']} // Lista de palabras clave sugeridas
                      value={currentKeyword}
                      onInputChange={(event, newInputValue) => {
                        setCurrentKeyword(newInputValue); // Actualiza la palabra clave ingresada
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Agregar Palabra Clave"
                          value={currentKeyword}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <IconButton
                                variant="outlined"
                                color="primary"
                                onClick={handleAddKeyword}
                                disabled={
                                  !currentKeyword || formData.seo.keywords.includes(currentKeyword)
                                } // Desactiva si no hay valor o ya existe
                              >
                                <AddIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Lista de Chips para Palabras Clave */}
                  <Grid item xs={12}>
                    <Stack
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))', // Distribución automática
                        gap: 1, // Espaciado entre Chips
                      }}
                    >
                      {formData.seo.keywords.map((keyword, index) => (
                        <Chip
                          key={index}
                          label={keyword}
                          onDelete={() => handleRemoveKeyword(keyword)}
                          color="info" // Cambia según diseño
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>


            </Grid>
            </Grid>
            

            <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {/* Botón Avanzado */}
              <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-end">
                {/* Título */}
                <Typography variant="h6" component="h2" style={{ whiteSpace: 'nowrap' }}>
                  Configuración de Media
                </Typography>

                {/* Divider */}
                <Box flex={1} marginLeft={2}>
                  <Divider />
                </Box>

                {/* Botón Avanzado */}
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<PhotoCamera />}
                  style={{ marginLeft: '16px' }} // Espaciado entre el Divider y el botón
                >
                  Subir Imágenes
                  <input
                    type="file"
                    hidden
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
              </Grid>
          
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  {formData.images.map((image, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                      <img
                        src={image}
                        alt={`product-${index}`}
                        style={{ width: 150, height: 150, objectFit: 'cover' }}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(index)}
                        size="small"
                        style={{
                          position: 'absolute',
                          top: -10,
                          right: -10,
                          backgroundColor: 'rgba(255,255,255,0.7)',
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                </Stack>
              </Grid>
         

            </Grid>
            </Grid>
            
            
          </Grid>
          </Grid>
          
          

          <Grid item xs={12} md={12}>
          <Grid container spacing={2}>

            
            
            {/* Botón para generar la descripción con IA */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={generateProductDescription}
                fullWidth
                disabled={loading}
              >
                {loading ? 'Generando Descripción...' : 'Generar Descripción con IA'}
              </Button>
            </Grid>

            {/* Mostrar error si lo hay */}
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                {productData ? 'Guardar Cambios' : 'Registrar Producto'}
              </Button>
            </Grid>
          
          </Grid>
          </Grid>


          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default ProductFormModal;
