// src/components/MainBanner.js
import React from 'react';
import { Tooltip, Fab } from '@mui/material';


const LoginTiktokButton = ({handleGoToTiktok , avatar_url, display_name}) => (

    
    <Tooltip title={display_name || 'TikTok'}    arrow>
        <Fab
            color="primary"
            aria-label="add"
            target='_blank'
            style={{
                position: 'fixed',
                backgroundColor: 'white',
                bottom: '20px',
                left: '30px',
                overflow: 'hidden',
            }}
            onClick={handleGoToTiktok} 
            >

            <img 
                src={avatar_url ? avatar_url : `${process.env.PUBLIC_URL}/assets/img/tiktokLogo.png`} 
                style={{
                    color: '#0000008a',
                    width: avatar_url ? 'auto' : '40px',  // Si avatar_url est défini, largeur auto ; sinon 40px
                    height: 'auto'
                }} 
            />

        </Fab>
    </Tooltip>

);

export default LoginTiktokButton;


 