import React from "react";
import SkeletonLoader from "./SkeletonLoader";
import LogoManager from '../../Logo/LogoManager';

const NavbarPreview = ({ storeName , compressedLogo ,items, isMenuOpen, toggleMenu }) => {
  return (
    <nav className="bg-white border-b border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between p-3">
        {/* Logo Placeholder */}
        <a
          href="#"
          className="flex items-center space-x-2 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <LogoManager storeName={storeName} compressedLogo={compressedLogo} />
        </a>

        {/* Hamburger Menu */}
        <button
          onClick={toggleMenu}
          className="md:hidden p-2 rounded text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>

        {/* Menu Links */}
        <div className={`hidden md:flex md:items-center md:w-auto`}>
          <ul className="flex flex-row items-center space-x-6">
            {items.map((item, index) => (
              <li key={index}>
                {item.type === "button" ? (
                  <button
                    onClick={() => (window.location.href = item.href)}
                    className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                  >
                    {item.label}
                  </button>
                ) : (
                  <a
                    href={item.href}
                    className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
                  >
                    {item.label}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
        } overflow-hidden transition-all duration-500 ease-in-out md:hidden w-full bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700`}
      >
        <ul className="flex flex-col space-y-4 p-4">
          {items.map((item, index) => (
            <li key={index}>
              {item.type === "button" ? (
                <button
                  onClick={() => (window.location.href = item.href)}
                  className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                >
                  {item.label}
                </button>
              ) : (
                <a
                  href={item.href}
                  className="block py-2 px-4 text-lg text-black hover:text-blue-500 dark:text-white dark:hover:text-blue-400"
                >
                  {item.label}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavbarPreview;
