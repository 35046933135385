import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import SkeletonLoader from './SkeletonLoader';
import CardServicio from './../CardServicio';

const ServicioPreview = ({ serviciosArray, onEdit, onDelete, width = 300, height = 200,user }) => {
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Simula 1.5 segundos de carga
    return () => clearTimeout(timer);
  }, [serviciosArray]);

  
  serviciosArray.forEach((servicio, index) => {
  
   
  });

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: height,
        padding: 2,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
     
        <h1 className="text-2xl font-semibold my-5"  style={{ color: '#255ce0', fontSize: '35px', fontWeight: '800' }}>Servicios</h1>
      

      <Grid
        container
        spacing={2}     
        sx={{
          maxWidth: '1300px',
          margin: '0 auto',
          width: '100%',
        }}
      >        

        {loading
          ? Array.from({ length: serviciosArray.length || 4 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={`skeleton-${index}`}>
                <SkeletonLoader width={width} height={height} />
              </Grid>
            ))
          : serviciosArray.length > 0
          ? serviciosArray.map((servicio) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={servicio.id}>
                <CardServicio
                  id={servicio.id} // Pasa el ID del servicio
                  titulo={servicio.titulo}
                  descripcion={servicio.descripcion}
                  icono={servicio.icono}
                  color={servicio.color}
                  onEdit={() => onEdit(servicio)} // Envía el servicio completo para editar
                  onDelete={() => onDelete(servicio.id)} // Envía solo el ID para eliminar
                  user={user}
                />
              </Grid>
            ))
          : !loading && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'darkgrey',
                  fontSize: '14px',
                  textAlign: 'center',
                  width: '100%',
                  height: '100%',
                  paddingTop: '5%',
                  paddingBottom: '5%',
                }}
              >
                             No hay servicios para mostrar

              </Box>
            )}
      </Grid>
    </Box>
  );
};

export default ServicioPreview;
