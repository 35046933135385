import React from 'react';

const PoliticaPrivacidad = () => {

  return (
    
    <div className="privacy-policy">
      <h1>Política de Privacidad</h1>
      
      <p><strong>Última actualización:</strong> 3 de diciembre de 2024</p>
      
      <h2>1. Información que Recopilamos</h2>
      <p>
        Recopilamos información personal que nos proporcionas directamente al crear una cuenta, realizar compras o interactuar con nuestra Plataforma. Esto puede incluir tu nombre, dirección de correo electrónico, dirección de facturación, y otra información relacionada con el uso de nuestra plataforma.
      </p>
      
      <h2>2. Uso de la Información</h2>
      <p>
        Usamos la información recopilada para procesar tus compras, gestionar tu cuenta, mejorar la Plataforma, y enviarte comunicaciones relacionadas con nuestros productos y servicios. También podemos utilizar tus datos para fines analíticos y de marketing, siempre respetando tus preferencias de privacidad.
      </p>
      
      <h2>3. Protección de la Información</h2>
      <p>
        Implementamos medidas de seguridad razonables para proteger tu información personal. Sin embargo, ningún sistema es completamente seguro, y no podemos garantizar la seguridad absoluta de tus datos.
      </p>
      
      <h2>4. Compartir Información</h2>
      <p>
        No vendemos ni alquilamos tu información personal a terceros. Podemos compartir tu información con proveedores de servicios de confianza que nos ayuden a operar la Plataforma, pero solo en la medida necesaria para ofrecerte nuestros servicios.
      </p>
      
      <h2>5. Derechos de los Usuarios</h2>
      <p>
        Tienes derecho a acceder, corregir o eliminar tus datos personales en cualquier momento. También puedes optar por no recibir comunicaciones de marketing, siguiendo las instrucciones en nuestros correos electrónicos o contactándonos directamente.
      </p>
      
      <h2>6. Cookies</h2>
      <p>
        Utilizamos cookies y tecnologías similares para mejorar tu experiencia de navegación. Puedes controlar el uso de cookies a través de la configuración de tu navegador, pero ten en cuenta que algunas funcionalidades de la Plataforma pueden no estar disponibles si desactivas las cookies.
      </p>
      
      <h2>7. Modificaciones a la Política de Privacidad</h2>
      <p>
        Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Las modificaciones serán efectivas al momento de su publicación en la Plataforma.
      </p>
      
      <h2>8. Contacto</h2>
      <p>
        Si tienes alguna pregunta sobre nuestra Política de Privacidad, por favor contáctanos a: [correo@empresa.com]
      </p>
    </div>
  );
};

export default PoliticaPrivacidad;
