import React, { useState } from 'react';
import { Modal, Box, TextField, Button ,Grid} from '@mui/material';
import { uploadFile } from '../../../utils/firebaseUtils'; // Ajusta según tu configuración
import compressBeneficios from '../utils/compressBeneficios';

const BeneficiosForm = ({ open, onClose, onSave, newBeneficio, handleInputChange, storeName }) => {
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    if (!newBeneficio.titulo || !newBeneficio.descripcion || !newBeneficio.porcentaje) {
      alert('Por favor completa todos los campos antes de agregar el beneficio.');
      return;
    }

    try {

      const compressedData = compressBeneficios({
        titulo: newBeneficio.titulo,
        descripcion: newBeneficio.descripcion,
        porcentaje: newBeneficio.porcentaje,
        color: newBeneficio.color || '#ffffff',
        colorStart: newBeneficio.colorStart || '#d9ddf1', // Color inicial del gradiente
        colorEnd: newBeneficio.colorEnd || 'rgba(233, 0, 0, 0%)', // Color final del gradiente
        angle: newBeneficio.angle || '180deg', // Asegurarnos de que el ángulo esté presente
      });
      onSave(compressedData);
      onClose();
    } catch (error) {
      console.error('Error al guardar el beneficio:', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-beneficio-modal"
      aria-describedby="modal-to-add-beneficio"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 600 }, // Ancho adaptable para dispositivos pequeños
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2 id="add-beneficio-modal">Agregar Beneficio</h2>
        
        {/* Grid para 2 columnas */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Título"
              name="titulo"
              value={newBeneficio.titulo}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Porcentaje"
              name="porcentaje"
              value={newBeneficio.porcentaje}
              onChange={handleInputChange}
              type="number"
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Descripción"
              name="descripcion"
              value={newBeneficio.descripcion}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: '16px' }}>
              <label htmlFor="color-start-picker" style={{ display: 'block', marginBottom: '8px' }}>
                Color Inicial del Gradiente:
              </label>
              <input
                type="color"
                id="color-start-picker"
                name="colorStart"
                value={newBeneficio.colorStart || ''}  // Valor por defecto
                onChange={handleInputChange}
                style={{
                  width: '100%',
                  height: '40px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: '16px' }}>
              <label htmlFor="color-end-picker" style={{ display: 'block', marginBottom: '8px' }}>
                Color Final del Gradiente:
              </label>
              <input
                type="color"
                id="color-end-picker"
                name="colorEnd"
                value={newBeneficio.colorEnd || ''}  // Valor por defecto
                onChange={handleInputChange}
                style={{
                  width: '100%',
                  height: '40px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Ángulo del Gradiente (en grados)"
              name="angle"
              value={newBeneficio.angle || '180deg'} // Valor predeterminado
              onChange={handleInputChange}
              helperText="Ejemplo: 45deg, 90deg, 180deg"
            />
          </Grid>
        </Grid>

        {/* Botón para Guardar */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSave}
          style={{ marginTop: '16px' }}
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Agregar'}
        </Button>
      </Box>
    </Modal>
  );
};

export default BeneficiosForm;
