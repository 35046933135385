import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const BlogAddButton = ({ onClick }) => (
    <Tooltip title="Añadir Blog" arrow>
        <Fab color="primary"
            aria-label="edit"
            onClick={onClick}
            style={{
                position: 'absolute',
                top: '10px',
                right: '1rem',
                transform: 'translateY(-50%)',
                backgroundColor: 'white',
            }}
        >
        <AddIcon style={{ color: '#0000008a' }}/>
        </Fab>
    </Tooltip>
);

export default BlogAddButton;
