import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const BlogEditButton = ({ onClick }) => (
    <Tooltip arrow>
        <Fab
            aria-label="edit"
            onClick={onClick}
            sx={{
                backgroundColor: 'white',
                boxShadow: 1,
                '&:hover': { backgroundColor: 'lightgrey' },
                width:'40px',
                height:'40px',
            }}
        >
            <EditIcon style={{ color: '#0000008a' }} />
        </Fab>
    </Tooltip>
);

export default BlogEditButton;