import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ServicioDeleteButton = ({ onClick }) => {
  



  return (
    <>
    
    <Tooltip title="Eliminar Servicio" arrow>
      <Fab
        color="secondary"
        aria-label="delete"
        style={{
            width: '20px',
            height:'20px',
            padding:'20px',
            backgroundColor: 'white',
          }}
        onClick={onClick} 
      >
        <DeleteIcon style={{ color: '#0000008a' }} />
      </Fab>
    </Tooltip>

</>
  );
};

export default ServicioDeleteButton;
