import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { db } from '../../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Navbar from '../../Top/Navbar/Navbar';
import NavbarManager from '../../Navbar/NavbarManager';
import FacilityArea from '../../FacilityArea';
import Footer from '../../Footer';
import {formatDate} from '../../../utils/formatDate';
import DOMPurify from 'dompurify';


const BlogDetail = () => {
    
    const { id } = useParams();
    const location = useLocation();
    const { storeName, date, title, image, description, shortDescription, tag = [] } = location.state || {}; // Destructura el state del Link
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    //console.log('storeName',storeName)


    useEffect(() => {
        const fetchPost = async () => {
            if (!storeName) {
                console.error("StoreName no proporcionado.");
                setLoading(false);
                return;
            }

            try {
                const collectionPath = `${storeName}/blog/entry`;
                const docRef = doc(db, collectionPath, id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setPost({ id: docSnap.id, ...docSnap.data() });
                } else {
                    console.log('¡No se encontró el documento!');
                }
            } catch (error) {
                console.error('Error al obtener el post:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPost();
    }, [id, storeName]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (!post) {
        return (
            <div>
                <h3>{title || 'Título no disponible'}</h3>
                <img src={image || 'assets/img/default.jpg'} alt={title || 'Imagen del Blog'} />
                <p>{shortDescription || 'Descripción no disponible'}</p>
                <p>{description || 'Descripción no disponible'}</p>
                <span>{date || 'Fecha no disponible'}</span>
                <div>
                    <ul className="category flex items-center mt-6 space-x-3 text-blue-500">
                        <li className="font-bold">Tags:</li>
                        {tag.map((t, index) => (
                            <li key={index}>
                                <a href="#" className="hover:underline">{t}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    const sanitizedDescription = DOMPurify.sanitize(post.description || 'Blog description...', {
        ALLOWED_TAGS: [
            'b','i','em','strong','a','p','ul','ol','li','br','span','div','img','h1','h2','h3','h4','h5','h6','blockquote','pre','code','button','textarea','form','input','select','option'],
            ALLOWED_ATTR: ['href', 'title', 'src', 'alt', 'style', 'type','value','name','placeholder','disabled','readonly','checked','required'],
    });

   
    return (
        <>
           
            <section className="blog-details-area pt-16 pb-16">
                <div className="container mx-auto px-2 lg:px-4">
                    <div className="flex flex-col items-center">
                        <div className="w-full max-w-4xl">
                            <div className="blog-details bg-white shadow-md rounded-lg">
                                <div className="article-img max-h-[600px] object-cover">
                                    <img
                                        src={post.image || 'assets/img/default.jpg'}
                                        alt={post.title || 'Imagen del Blog'}
                                        className="w-full rounded-t-lg h-[300px] md:h-[600px] object-cover object-center"
                                    />
                                </div>

                                <div className="article-content p-4 lg:p-6">
                                    <ul className="entry-meta grid grid-cols-2 md:flex md:space-x-4 text-gray-500 text-sm mb-4">
                                        <li className="d-flex items-center justify-start md:justify-center">
                                            <i className="far fa-user mr-2"></i>
                                            <a href="#" className="hover:underline">{storeName || 'Author'}</a>
                                        </li>
                                        <li className="d-flex items-center justify-start md:justify-center">
                                            <i className="far fa-calendar-alt mr-2"></i>
                                            {formatDate(post.date) || 'Date'}
                                        </li>
                                        <li className="d-flex items-center justify-start md:justify-center">
                                            <i className="far fa-comment-dots mr-2"></i>
                                            {post.commentsCount || 0} Comments
                                        </li>
                                    </ul>

                                    <h3 className="text-2xl font-bold text-gray-800 mb-4 text-left">{post.title || 'Blog Title'}</h3>

                                    <p className="text-gray-600 mb-4 text-left">{post.shortDescription || 'Blog short description...'}</p>
                                    
                                    <div
                                        className="text-gray-600 mb-4 text-left"
                                        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                                    ></div>

                                    {/*<blockquote className="border-l-4 border-blue-500 pl-4 italic text-gray-500 mb-4 text-left">
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
                                        <cite className="block text-gray-700 mt-2">Tom Cruise</cite>
                                    </blockquote>*/}
                                    <p className="text-gray-600 mb-4 text-left">
                                        Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.
                                    </p>

                                    <ul className="category flex items-center mt-6 space-x-3 text-blue-500">
                                        <li className="font-bold">Tags:</li>
                                        {(Array.isArray(post.tag) && post.tag.length > 0 ? post.tag : ["General"]).map((t, index) => (
                                            <li key={index}>
                                                <a href="#" className="">{t}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            {/* 
                            <div className="comments-area mt-8">
                                <h3 className="text-xl font-bold text-gray-800 mb-4">2 Comments:</h3>

                                <ul className="space-y-6">
                                    <li className="comment flex space-x-4">
                                        <img src="assets/img/author1.jpg" alt="Author" className="w-12 h-12 rounded-full" />
                                        <div>
                                            <div className="text-gray-800 font-bold">Comero <span className="text-gray-500">says:</span></div>
                                            <div className="text-sm text-gray-500 mb-2">April 24, 2024 at 10:59 am</div>
                                            <p className="text-gray-600">
                                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.
                                            </p>
                                            <a href="#" className="text-blue-500 hover:underline mt-2 inline-block">Reply</a>
                                        </div>
                                    </li>
                                </ul>

                                <div className="comment-respond mt-8">
                                    <h3 className="text-xl font-bold text-gray-800 mb-4">Leave a Reply</h3>

                                    <form className="space-y-4">
                                        <textarea
                                            name="comment"
                                            placeholder="Comment"
                                            className="w-full border border-gray-300 rounded-md p-4"
                                            rows="5"
                                        ></textarea>
                                        <input
                                            type="text"
                                            name="author"
                                            placeholder="Name *"
                                            className="w-full border border-gray-300 rounded-md p-4"
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email *"
                                            className="w-full border border-gray-300 rounded-md p-4"
                                            required
                                        />
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600"
                                        >
                                            Post Comment
                                        </button>
                                    </form>
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default BlogDetail;