import React from 'react';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddStoreButton = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      backgroundColor: 'white',
      boxShadow: 1,
      '&:hover': { backgroundColor: 'lightgrey' },
    }}
  >
    <AddIcon />
  </IconButton>
);

export default AddStoreButton;
