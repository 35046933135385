import React from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonLoader = () => {
  return (
    
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow: 1,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: 6,
        },
      }}
    >
      {/* Skeleton del ícono */}
      <Box
        sx={{
          width: 40,
          height: 40,
          backgroundColor: 'grey.300',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Skeleton variant="circular" width={40} height={40} />
      </Box>

      {/* Skeleton del contenido */}
      <Box>
        <Skeleton variant="text" width="60%" height={20} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="90%" height={20} />
      </Box>
    </Box>
  );
};

export default SkeletonLoader;
