import React from 'react';
import { Fab, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const BannerEditButton = ({ onClick }) => (
    <Tooltip title="Editar Banner" arrow>
        <Fab
            aria-label="edit"
            onClick={onClick}
            style={{
                position: 'absolute',
                top: '50%',
                right: '1rem', 
                zIndex: 1,
                width:'40px',
                height:'40px',
                backgroundColor: 'white',
                boxShadow: 1,
                '&:hover': { backgroundColor: 'lightgrey' },
            }}
        >
            <EditIcon />
        </Fab>
    </Tooltip>
);

export default BannerEditButton;
