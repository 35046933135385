import React from "react";

const BlogSkeleton = () => {
  return (
    <section className="py-10 relative">
      <div className="container mx-auto">
        <div className="gap-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="single-blog-post bg-white shadow-md rounded-lg overflow-hidden animate-pulse"
            >
              <div className="blog-image relative w-full h-[300px] bg-gray-300"></div>
              <div className="p-4 flex flex-col items-start text-left">
                <div className="date w-1/4 h-4 bg-gray-300 rounded mb-3"></div>
                <div className="title w-3/4 h-6 bg-gray-300 rounded mb-3"></div>
                <div className="description w-full h-4 bg-gray-300 rounded mb-2"></div>
                <div className="description w-5/6 h-4 bg-gray-300 rounded"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSkeleton;