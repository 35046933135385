import React from 'react';
import { IconButton,Tooltip  } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const LogOutButton = ({ onClick }) => (
    <Tooltip title="Cerrar sesión" arrow>
        <IconButton
            onClick={onClick}
            title="Cerrar sesión"
            sx={{
            backgroundColor: 'white',
            boxShadow: 1,
            '&:hover': { backgroundColor: 'lightgrey' },
            }}
        >
            <LogoutOutlinedIcon />
        </IconButton>
    </Tooltip>
);

export default LogOutButton;
