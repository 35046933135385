import React, { useState } from 'react';
import BeneficiosEditButton from './components/BeneficiosEditButton';
import BeneficiosDeleteButton from './components/BeneficiosDeleteButton';

const CardBeneficio = ({ id, titulo, descripcion, color, porcentaje, onEdit, onDelete, user,colorStart,colorEnd,angle}) => {

  return (


    <div
      className="rounded-lg flex items-start gap-2 flex-col shadow-efecto gap-2 transition-transform transition-shadow duration-300 hover:shadow-card hover:-translate-y-2"
      style={{
        padding: '40px',
        borderRadius: '20px',
        background: `linear-gradient(${angle}, ${colorStart}, ${colorEnd})`,
      }}
    >
      <div className="flex justify-between w-full items-center">
        <div>
          <p style={{ color: color, fontSize: '50px', fontWeight: '700' }} className="">
            {porcentaje}%
          </p>
        </div>

        <div className="flex gap-2">
          {user && (
            <>
              <BeneficiosEditButton onClick={() => onEdit({ id, titulo, descripcion, color, porcentaje })} />
              <BeneficiosDeleteButton onClick={() => onDelete(id)} />
            </>
          )}
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <p className="text-left font-bold text-xl text-[20px] text-black">{titulo}</p>
        <p className="text-left font-normal text-base text-black max-h-[100px] min-h-[100px] line-clamp-4">{descripcion}</p>
      </div>

      
    </div>
  );
};

export default CardBeneficio;
