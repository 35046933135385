import React, { useState, useEffect } from 'react';
import { Tooltip, Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ServicioAddButton = ({ onClick }) => {



  return (
    <>

    <Tooltip title="Agregar Servicio" arrow>
      <Fab
        color="primary"
        aria-label="add"
        style={{
          position: 'relative',
          bottom: '170px',
          right: '-905px',
          backgroundColor: 'white',
        }}
        onClick={onClick} 
      >
        <AddIcon style={{ color: '#0000008a' }} />
      </Fab>
    </Tooltip>

</>
  );
};

export default ServicioAddButton;
