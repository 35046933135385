import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Fab, Box, Tooltip } from '@mui/material';

const ProductoAddButton = ({ user, onClick }) => (
    <>
    {user && (
            <Box sx={{ position: 'relative' }}>
              <Tooltip title="Agregar Producto" arrow>
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={onClick}
                  sx={{
                    position: 'absolute',
                    top: 100,
                    right: 16,
                    zIndex: 1,
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: 'white' },
                  }}
                >
                  <AddIcon style={{ color: '#0000008a' }} />
                </Fab>
              </Tooltip>
            </Box>
    )}
    </>
);

export default ProductoAddButton;
