import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteStoreButton = ({ onDelete }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirmDelete = () => {
    onDelete(); // Ejecuta la función de eliminación pasada como prop
    handleClose(); // Cierra el modal
  };

  return (
    <>
      {/* Botón para abrir el modal */}
      <IconButton
        onClick={handleOpen}
        sx={{
          backgroundColor: 'white',
          boxShadow: 1,
          '&:hover': { backgroundColor: 'lightgrey' },
        }}
      >
        <DeleteIcon />
      </IconButton>

      {/* Modal de confirmación */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas eliminar esta tienda? Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" variant="contained">
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteStoreButton;
